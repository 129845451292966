import { createSlice } from "@reduxjs/toolkit";

import { request } from "@services/Request";

import { tokenStorageKey } from "@constants/localStorage";

import { IClientInfo } from "@schemas/clients";

import { sendTaps } from "@redux/vote/vote.thunk";

import { isNumber } from "@utils/number/isNumber";

import { fetchUserInfo, fetchUserToken } from "./user.thunk";

export interface IUserState {
    isAuth: boolean;
    isLoading: boolean;
    isUserInfoLoading: boolean;
    userInfo: IClientInfo | null;
}

const initialState: IUserState = {
    isAuth: false,
    isLoading: false,
    isUserInfoLoading: false,
    userInfo: null,
};

export const userSlice = createSlice({
    initialState,
    name: "user",
    reducers: {
        setAuth(state, { payload }) {
            state.isAuth = payload;
        },
        incrementLuckySpinTiket(state) {
            if (state.userInfo) state.userInfo.lucky_spin++;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserToken.fulfilled, (state, { payload }) => {
                const { access_token } = payload;

                localStorage.setItem(tokenStorageKey, access_token);
                request.setToken(access_token);
                state.isAuth = true;
                state.isLoading = false;
            })
            .addCase(fetchUserToken.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchUserToken.rejected, (state) => {
                state.isLoading = false;
            });

        builder
            .addCase(fetchUserInfo.fulfilled, (state, { payload }) => {
                state.userInfo = payload;
                state.isUserInfoLoading = false;
            })
            .addCase(fetchUserInfo.pending, (state) => {
                state.isUserInfoLoading = true;
            })
            .addCase(fetchUserInfo.rejected, (state) => {
                state.isUserInfoLoading = false;
            });

        builder.addCase(sendTaps.fulfilled, (state, { payload }) => {
            const votes_free = state.userInfo?.votes_free;

            if (
                payload.free_vote_claimed &&
                state.userInfo &&
                isNumber(votes_free)
            ) {
                state.userInfo.votes_free = votes_free + 1;
            }
        });
    },
});

export const userActions = { ...userSlice.actions };

export const userReducer = userSlice.reducer;
