// import useCountdown from "@hooks/useCountdown";
import Typography from "@atoms/Typography";

import { RedStarSVG } from "@svg/RedStarSVG";

import styles from "./CountDown.module.scss";

// const timestamp = new Date(Date.UTC(2024, 11, 6, 5, 0, 0)).getTime();

export const CountDown = () => {
    // const { timeRemaining: countDownTime } = useCountdown(timestamp);

    const [hours = "00", minutes = "00", seconds = "00"] = []; // countDownTime.split(":");

    return (
        <div className={styles.wrapper}>
            <div className={styles.stars}>
                {[...Array(3)].map((_, index) => (
                    <RedStarSVG key={index} />
                ))}
            </div>

            <Typography variant="textRegular">General Voting!</Typography>

            <div className={styles.bottom}>
                <RedStarSVG />

                <div className={styles.countdown}>
                    <Typography className={styles.time} variant="textRegular">
                        {hours}
                        <Typography
                            className={styles.grayHour}
                            variant="text3Regular"
                        >
                            hh
                        </Typography>
                    </Typography>
                    <span className={styles.double}>:</span>
                    <Typography className={styles.time} variant="textRegular">
                        {minutes}
                        <Typography
                            className={styles.grayMinute}
                            variant="text3Regular"
                        >
                            mm
                        </Typography>
                    </Typography>
                    <span className={styles.double}>:</span>
                    <Typography className={styles.time} variant="textRegular">
                        {seconds}
                        <Typography
                            className={styles.graySeconds}
                            variant="text3Regular"
                        >
                            ss
                        </Typography>
                    </Typography>
                </div>

                <div className={styles.stars}>
                    {[...Array(3)].map((_, index) => (
                        <RedStarSVG key={index} />
                    ))}
                </div>
            </div>
        </div>
    );
};
