import IconBlock from "@atoms/IconBlock";
import TransparentBlock from "@atoms/TransparentBlock/TransparentBlock";
import Typography from "@atoms/Typography";

import styles from "../govern-screen.module.scss";

const TypesOfVoteBlock = () => {
    return (
        <>
            <Typography variant="textBold">3 types of Votes</Typography>
            <div className={styles.typesOfVoteBlock}>
                <TransparentBlock rootClassName={styles.typesBlock}>
                    <IconBlock
                        borderRadius="big"
                        className={styles.iconBlock}
                        mode="gray"
                    >
                        1
                    </IconBlock>
                    <Typography variant="text2Regular">
                        Vote for Telegram Stars{" "}
                    </Typography>
                </TransparentBlock>
                <TransparentBlock rootClassName={styles.typesBlock}>
                    <IconBlock
                        borderRadius="big"
                        className={styles.iconBlock}
                        mode="gray"
                    >
                        2
                    </IconBlock>
                    <Typography variant="text2Regular">
                        1 Free Vote per day from taps
                    </Typography>
                </TransparentBlock>
                <TransparentBlock rootClassName={styles.typesBlock}>
                    <IconBlock
                        borderRadius="big"
                        className={styles.iconBlock}
                        mode="gray"
                    >
                        3
                    </IconBlock>
                    <Typography variant="text2Regular">
                        Free Votes from referal program
                    </Typography>
                </TransparentBlock>

                <TransparentBlock rootClassName={styles.radiusBlock}>
                    <Typography variant="text2Regular">
                        1 Free Vote = 1 PreLuckySpin ticket*
                    </Typography>
                    <Typography
                        className={styles.marginText}
                        variant="text2Regular"
                    >
                        1 Vote = 1 LuckySpin ticket*
                    </Typography>
                </TransparentBlock>

                <Typography variant="text3Regular">
                    *Each ticket increases your chanses of winning
                </Typography>

                <TransparentBlock rootClassName={styles.radiusBlock}>
                    <Typography
                        style={{ lineHeight: "20px" }}
                        variant="text2Regular"
                    >
                        Stars for Votes goes to the prize fund of the LuckySpin,
                        and 50% of all stars will be shared among 100 random
                        participants.
                    </Typography>
                </TransparentBlock>
            </div>
        </>
    );
};

export default TypesOfVoteBlock;
