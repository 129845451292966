import { FC } from "react";

import IconBlock from "@atoms/IconBlock";
import SunSpinner from "@atoms/SunSpinner";
import TransparentBlock from "@atoms/TransparentBlock/TransparentBlock";
import Typography from "@atoms/Typography";

import { DoubleSuccessSVG } from "@svg/DoubleSuccessSVG";
import { PeopleSVG } from "@svg/PeopleSVG";

import styles from "../TasksScreen.module.scss";

interface ITotalBlock {
    totalFrens: number;
    totalFreeVotes: number;
    isLoading: boolean;
}

const TotalBlock: FC<ITotalBlock> = ({
    totalFrens,
    totalFreeVotes,
    isLoading,
}) => {
    if (isLoading) return <SunSpinner />;

    return (
        <div className={styles.totalBlock}>
            <TransparentBlock rootClassName={styles.totalContainer}>
                <div className={styles.totalBlockItem}>
                    <Typography variant="textSemibold">{totalFrens}</Typography>
                    <Typography variant="text3Regular">
                        Friends invited
                    </Typography>
                </div>

                <IconBlock className={styles.totalIcon} mode="gray">
                    <PeopleSVG />
                </IconBlock>
            </TransparentBlock>
            <TransparentBlock rootClassName={styles.totalContainer}>
                <div className={styles.totalBlockItem}>
                    <Typography variant="textSemibold">
                        {totalFreeVotes}
                    </Typography>
                    <Typography variant="text3Regular">
                        Free Votes recieved
                    </Typography>
                </div>

                <IconBlock className={styles.totalIcon} mode="gray">
                    <DoubleSuccessSVG />
                </IconBlock>
            </TransparentBlock>
        </div>
    );
};

export default TotalBlock;
