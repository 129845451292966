import { useEffect } from "react";

import { useLocation } from "react-router-dom";

import { TAppRoute, routes } from "@constants/routes";

import { applicationCandidateFormFieldsSelector } from "@redux/nomi/nomi.selectors";
import { nomiActions } from "@redux/nomi/nomi.slice";

import { useAppDispatch } from "./useAppDispatch";
import { useAppSelector } from "./useAppSelector";

const allowedPaths: TAppRoute[] = [
    routes.applicationForm,
    routes.additionTerms,
    routes.privacyPolicy,
    routes.termsCondition,
] as const;

export const useClearNomiForm = () => {
    const location = useLocation();

    const fields = useAppSelector(applicationCandidateFormFieldsSelector);

    const dispatch = useAppDispatch();

    useEffect(() => {
        const currentPath = location.pathname as TAppRoute;

        if (fields && !allowedPaths.includes(currentPath)) {
            dispatch(nomiActions.clearApplicationCandidateFormFields());
        }
    }, [dispatch, fields, location.pathname]);
};
