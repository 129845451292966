import { useEffect } from "react";

import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";

import TransparentBlock from "@atoms/TransparentBlock/TransparentBlock";
import Typography from "@atoms/Typography";

import { constantsSelector } from "@redux/constants/constants.selectors";
import { fetchConstants } from "@redux/constants/constants.thunk";

import star from "../../../../assets/images/Star.png";
import styles from "../govern-screen.module.scss";

const LuckySpinBlock = () => {
    const dispatch = useAppDispatch();

    const constants = useAppSelector(constantsSelector);

    useEffect(() => {
        dispatch(fetchConstants("prize_pool"));
    }, [dispatch]);

    return (
        <div className={styles.luckySpinBlock}>
            <Typography variant="textBold">LuckySpin</Typography>

            <TransparentBlock rootClassName={styles.radiusBlock}>
                <Typography style={{ lineHeight: "20px" }} variant="text2Bold">
                    <Typography element="span" variant="text2Regular">
                        Tap the system is where{" "}
                    </Typography>
                    you can win Telegram Stars in LuckySpin.
                </Typography>
            </TransparentBlock>

            <div className={styles.blueSpinBlock}>
                <Typography variant="text2Bold">LuckySpin fund</Typography>

                <div className={styles.stars}>
                    <img alt="star" className={styles.star} src={star} />
                    <Typography variant="text2Bold">
                        {constants.data[0].value} Telegram Stars
                    </Typography>
                    <img alt="star" className={styles.star} src={star} />
                </div>
            </div>

            <TransparentBlock rootClassName={styles.radiusBlock}>
                <Typography variant="text2Bold">PreLuckySpin</Typography>
                <Typography
                    className={styles.marginText}
                    style={{ lineHeight: "20px" }}
                    variant="text2Regular"
                >
                    the premain prize draw begins at the end of General Voting,
                    with 10 users winning free Lucky Spin tickets daily from
                    07.11.2024 to 28.11.2024.
                </Typography>
            </TransparentBlock>

            <TransparentBlock rootClassName={styles.radiusBlock}>
                <Typography variant="text2Bold">LuckySpin</Typography>
                <Typography
                    className={styles.marginText}
                    style={{ lineHeight: "20px" }}
                    variant="text2Regular"
                >
                    the main prize draw will take place on 29.11.2024, awarding
                    Telegram Stars to 100 random participants
                </Typography>
            </TransparentBlock>
        </div>
    );
};

export default LuckySpinBlock;
