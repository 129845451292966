import { AxiosPromise } from "axios";

import { API_URLS } from "@constants/api";

import {
    ILeaderboard,
    IReferral,
    IReferralInfo,
} from "@redux/referrals/referrals.slice";

import Request from "../../Request";

const url = API_URLS.referrals;

export class ReferralsAPI {
    static getReferralsInfo(): AxiosPromise<IReferralInfo> {
        return Request.get(`${url}/info/`);
    }

    static getReferralsList(): AxiosPromise<IReferral[]> {
        return Request.get(`${url}/list/`);
    }

    static getLeaderboard(): AxiosPromise<ILeaderboard> {
        return Request.get(`${url}/leaders/`);
    }

    static claimTaps(): AxiosPromise<IReferralInfo> {
        return Request.post(`${url}/claim/taps/`);
    }
}
