import Typography from "@atoms/Typography";

import MainLayout from "@organisms/layouts/MainLayout";

import settings from "../../../assets/images/Settings.png";
import styles from "./heavy-load.module.scss";

const HeavyLoadScreen = () => {
    return (
        <MainLayout additionalScrollHeight={10} hasNavbar={false} title="">
            <div className={styles.wrapper}>
                <img alt="settings" src={settings} />
                <Typography variant="h1Bold">
                    Heavy load
                    <br />
                    detected
                </Typography>
                <Typography className={styles.descText} variant="textRegular">
                    The app is currently experiencing increased load due to the
                    number of players.
                </Typography>
                <Typography variant="textRegular">
                    Try to come back in 10 minutes
                </Typography>
            </div>
        </MainLayout>
    );
};

export default HeavyLoadScreen;
