import { FC } from "react";

import { ISvg } from "./SVG.types";

export const CheckSVG: FC<ISvg> = (props) => {
    return (
        <svg
            fill="none"
            height="16"
            viewBox="0 0 24 24"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4 12.6111L8.92308 17.5L20 6.5"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
        </svg>
    );
};
