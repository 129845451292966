import { createSlice } from "@reduxjs/toolkit";

import { fetchTasks, sendCheckTelegramFollow } from "./task.thunk";

export interface ITask {
    id: number;
    title: string;
    description: string;
    task_type: string;
    reward_type: string;
    reward_amount: number;
    url?: any;
    status: string;
    claim_count: number;
}

export interface ITaskState {
    data: ITask[];
    isTasksLoading: boolean;
    isCheckFollowLoading: boolean;
}

const initialState: ITaskState = {
    data: [],
    isTasksLoading: false,
    isCheckFollowLoading: false,
};

export const tasksSlice = createSlice({
    initialState,
    name: "tasks",
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTasks.fulfilled, (state, { payload }) => {
                state.data = payload;
                state.isTasksLoading = false;
            })
            .addCase(fetchTasks.pending, (state) => {
                state.isTasksLoading = true;
            })
            .addCase(fetchTasks.rejected, (state) => {
                state.isTasksLoading = false;
            });

        builder
            .addCase(sendCheckTelegramFollow.fulfilled, (state) => {
                state.isCheckFollowLoading = false;
            })
            .addCase(sendCheckTelegramFollow.pending, (state) => {
                state.isCheckFollowLoading = true;
            })
            .addCase(sendCheckTelegramFollow.rejected, (state) => {
                state.isCheckFollowLoading = false;
            });
    },
});

export const tasksActions = { ...tasksSlice.actions };

export const tasksReducer = tasksSlice.reducer;
