import { AxiosPromise } from "axios";

import { API_URLS } from "@constants/api";

import { IVotePayment, IVoteSuccess } from "@schemas/votes";

import Request from "../../Request";
import { ISendVoteDto } from "./dto/send-vote.dto";

const url = API_URLS.vote;

export class VoteAPI {
    static sendFreeVote(dto: ISendVoteDto): AxiosPromise<IVoteSuccess> {
        return Request.post(`${url}/vote/free/`, dto);
    }

    static sendStarsVote(dto: ISendVoteDto): AxiosPromise<IVotePayment> {
        return Request.post(`${url}/vote/stars/`, dto);
    }
}
