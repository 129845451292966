import { AxiosPromise } from "axios";

import Request from "@services/Request";

import { API_URLS } from "@constants/api";

import {
    ICandidateUrl,
    ICandidatesGroup,
    IMyCandidate,
    IPublicCandidate,
} from "@schemas/candidates";
import { IManagedPaginationData } from "@schemas/common";

import { ICreateMyCandidateDto } from "./dto/create-my-candidate.dto";
import { IPublishCandidateDto } from "./dto/publish-candidate.dto";

const url = API_URLS.candidates;

export class CandidatesAPI {
    static getMyCandidatesList(): AxiosPromise<IMyCandidate[]> {
        return Request.get(`${url}/my/list/`);
    }

    static createMyCandidate(
        dto: ICreateMyCandidateDto
    ): AxiosPromise<IMyCandidate> {
        const formData = new FormData();

        formData.append("name", dto.name);
        formData.append("photo", dto.photo);
        dto.countriesId.forEach((countryId) => {
            formData.append("countries_ids", countryId.toString());
        });

        return Request.post(`${url}/my/`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    }

    static getPublicCandidatesList(): AxiosPromise<ICandidatesGroup> {
        return Request.get(`${url}/list/`);
    }

    static getCandidatesListByCategory(
        page: number
    ): AxiosPromise<IManagedPaginationData<IPublicCandidate>> {
        return Request.get(`${url}/category/nomines/list`, {
            params: { page },
        });
    }

    static publishCandidate(
        dto: IPublishCandidateDto
    ): AxiosPromise<ICandidateUrl> {
        return Request.post(`${url}/my/publish/`, dto);
    }
}
