import clsx from "clsx";
import { createPortal } from "react-dom";

import Button from "@atoms/Button";
import Typography from "@atoms/Typography";

import { CloseSvg } from "@svg/CloseSVG";

import styles from "./Modal.module.scss";
import { IModal } from "./Modal.types";

const Modal: FCC<IModal> = ({
    onClose,
    open,
    children,
    title,
    titleClassName,
    className,
    windowClassName,
}) => {
    return createPortal(
        <div
            className={clsx(styles.wrapper, className, {
                [styles.wrapper_open]: open,
            })}
        >
            <div
                className={clsx(styles.bg, {
                    [styles.bg_open]: open,
                })}
            />
            <div
                className={clsx(styles.window, windowClassName, {
                    [styles.window_open]: open,
                })}
            >
                <div className={styles.body}>
                    <CloseSvg className={styles.close} onClick={onClose} />
                    <Typography
                        className={clsx(styles.title, titleClassName)}
                        variant="textRegular"
                    >
                        {title}
                    </Typography>

                    <div className={styles.content}>{children}</div>
                </div>
            </div>
        </div>,
        document.getElementsByTagName("body")[0]
    );
};

export default Modal;
