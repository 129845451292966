import { createSlice } from "@reduxjs/toolkit";

import { fetchConstants } from "./constants.thunk";

export type IConstantKey =
    | "prize_pool"
    | "publish_candidate"
    | "early_voting_timestamp"
    | "main_reward_timestamp";

export interface IConstants {
    key: IConstantKey;
    value: string;
}

export interface IConstantsState {
    data: IConstants[];
}

const initialState: IConstantsState = {
    data: [
        {
            key: "prize_pool",
            value: "0",
        },
        {
            key: "publish_candidate",
            value: "0",
        },
        {
            key: "early_voting_timestamp",
            value: "0",
        },
        {
            key: "main_reward_timestamp",
            value: "0",
        },
    ],
};

export const constantsSlice = createSlice({
    initialState,
    name: "constants",
    reducers: {
        setConstants: (state, { payload }) => {
            state.data = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchConstants.fulfilled, (state, { payload }) => {
            state.data = payload;
        });
    },
});

export const { setConstants } = constantsSlice.actions;

export default constantsSlice.reducer;
