import { FC } from "react";

import { ISvg } from "./SVG.types";

export const LightningSVG: FC<ISvg> = (props) => {
    return (
        <svg
            fill="none"
            height="12"
            viewBox="0 0 12 12"
            width="12"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M10.0246 5.88092L4.77456 11.5059C4.71892 11.5653 4.64548 11.605 4.56533 11.6189C4.48517 11.6329 4.40264 11.6204 4.33019 11.5834C4.25775 11.5463 4.19932 11.4867 4.16372 11.4136C4.12811 11.3404 4.11728 11.2577 4.13284 11.1778L4.82002 7.74045L2.11862 6.72607C2.0606 6.70437 2.00887 6.66864 1.96803 6.62206C1.92719 6.57549 1.89853 6.51952 1.8846 6.45916C1.87067 6.39881 1.87191 6.33594 1.88821 6.27618C1.90451 6.21642 1.93535 6.16163 1.97799 6.1167L7.22799 0.491698C7.28363 0.432324 7.35707 0.392657 7.43722 0.378681C7.51738 0.364706 7.59991 0.377181 7.67236 0.414224C7.7448 0.451267 7.80323 0.510868 7.83883 0.584033C7.87444 0.657199 7.88527 0.739958 7.86971 0.819823L7.18065 4.26092L9.88206 5.27389C9.93964 5.29573 9.99097 5.33143 10.0315 5.37782C10.072 5.42421 10.1005 5.47987 10.1144 5.53987C10.1283 5.59988 10.1272 5.66238 10.1112 5.72186C10.0952 5.78135 10.0648 5.83597 10.0227 5.88092H10.0246Z"
                fill="white"
            />
        </svg>
    );
};
