import { FC } from "react";

import clsx from "clsx";

import Typography from "@atoms/Typography";

import { InfoSVG } from "@svg/InfoSVG";

import { IStatusTag } from "../CandidateBlock.types";
import styles from "../candidates-block.module.scss";

const StatusTag: FC<IStatusTag> = ({ status }) => {
    return (
        <div
            className={clsx(
                styles.statusBlock,
                styles[`statusBlock__${status}`]
            )}
        >
            <InfoSVG className={styles.infoIcon} />
            <Typography variant="text3Regular">{status}</Typography>
        </div>
    );
};

export default StatusTag;
