import { AxiosPromise } from "axios";

import Request from "@services/Request";

import { API_URLS } from "@constants/api";

import { IClientInfo } from "@schemas/clients";

const url = API_URLS.clients;

export class ClientsAPI {
    static getUserInfo(): AxiosPromise<IClientInfo> {
        return Request.get(`${url}/me/`);
    }
}
