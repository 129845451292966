import { AxiosPromise } from "axios";

import { API_URLS } from "@constants/api";

import { ITask } from "@redux/tasks/task.slice";

import Request from "../../Request";

const url = API_URLS.tasks;

export class TasksAPI {
    static getTasks(): AxiosPromise<ITask[]> {
        return Request.get(`${url}/list/`);
    }

    static checkFollowOnChannel(taskId: number): AxiosPromise<ITask[]> {
        return Request.post(`${url}/check/telegram/`, { task_id: taskId });
    }

    static claimTask(taskId: number): AxiosPromise<ITask[]> {
        return Request.post(`${url}/claim/`, { task_id: taskId });
    }
}
