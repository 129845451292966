import { FC } from "react";

import { ISvg } from "./SVG.types";

export const TelegramSVG: FC<ISvg> = (props) => {
    return (
        <svg
            fill="none"
            height="16"
            viewBox="0 0 18 16"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M17.4034 0.164085C17.328 0.0989832 17.2363 0.0556981 17.1382 0.0389012C17.04 0.0221042 16.9391 0.0324329 16.8464 0.0687729L1.01356 6.26487C0.820984 6.34022 0.658052 6.47602 0.549234 6.65187C0.440416 6.82771 0.391587 7.03412 0.410079 7.24008C0.428571 7.44605 0.513386 7.64046 0.651787 7.79411C0.790188 7.94776 0.974703 8.05236 1.17762 8.09221L5.40575 8.92268V13.625C5.40584 13.843 5.47107 14.056 5.59307 14.2367C5.71507 14.4174 5.88828 14.5574 6.09046 14.639C6.29264 14.7205 6.51457 14.7397 6.72777 14.6942C6.94097 14.6487 7.13571 14.5406 7.287 14.3836L9.36825 12.225L12.6167 15.0727C12.815 15.2482 13.0706 15.3451 13.3354 15.3453C13.4511 15.3451 13.566 15.3269 13.6761 15.2914C13.8565 15.2343 14.0189 15.1309 14.1469 14.9915C14.2749 14.8521 14.3642 14.6816 14.4057 14.4969L17.5792 0.700804C17.6015 0.603815 17.5969 0.502576 17.5659 0.407997C17.535 0.313418 17.4788 0.229085 17.4034 0.164085ZM1.34715 7.16955C1.34416 7.16149 1.34416 7.15262 1.34715 7.14455C1.35068 7.14183 1.35464 7.13972 1.35887 7.1383L13.8362 2.25393L5.7659 8.03518L1.35887 7.17268L1.34715 7.16955ZM6.612 13.7321C6.59047 13.7544 6.56277 13.7698 6.53243 13.7764C6.5021 13.7829 6.47051 13.7803 6.44168 13.7688C6.41285 13.7573 6.3881 13.7375 6.37057 13.7119C6.35304 13.6863 6.34353 13.6561 6.34325 13.625V9.57112L8.66278 11.6024L6.612 13.7321ZM13.4925 14.2852C13.4867 14.3116 13.4739 14.336 13.4555 14.3558C13.4371 14.3756 13.4137 14.39 13.3878 14.3977C13.3613 14.407 13.3329 14.409 13.3054 14.4035C13.2779 14.398 13.2524 14.3852 13.2315 14.3664L6.62762 8.57346L16.4214 1.55471L13.4925 14.2852Z"
                fill="white"
            />
        </svg>
    );
};
