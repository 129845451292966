import { useNavigate } from "react-router-dom";

import {
    hideStartScreenValue,
    isShowStartScreensKey,
} from "@constants/localStorage";
import { routes } from "@constants/routes";

import Button from "@atoms/Button";
import Typography from "@atoms/Typography";

import MainLayout from "@organisms/layouts/MainLayout";

import styles from "./rules-screen.module.scss";

const RulesScreen = () => {
    const navigate = useNavigate();

    const handleStartTheGame = () => {
        localStorage.setItem(isShowStartScreensKey, hideStartScreenValue);
        navigate(routes.vote);
    };

    return (
        <MainLayout
            additionalScrollHeight={10}
            bgName="RulesBg"
            hasNavbar={false}
            title=""
        >
            <div className={styles.wrapper}>
                <div className={styles.topBlock}>
                    <Typography variant="display">HOW TO PLAY</Typography>
                    <Button
                        className={styles.startBtn}
                        onClick={handleStartTheGame}
                    >
                        Start the game
                    </Button>
                </div>

                <div className={styles.rulesBlock}>
                    <Typography variant="text2Bold">
                        They say the U.S. presidential election sets the stage
                        for a new world order, but only Americans vote. That`s
                        unfair.
                    </Typography>
                    <Typography variant="text2Bold">
                        Tap the system changing that—now the whole world picks
                        the global ruler! Our ballot features politicians and
                        influencers from around the world. You choose who leads.
                    </Typography>
                    <Typography variant="text2Regular">
                        Tap the system presents the candidates list of
                        politicians, influencers and Public Nominees from around
                        the world. Voting is powered by the best global
                        practices:
                    </Typography>

                    <Typography className={styles.list} variant="text2Regular">
                        <ul>
                            <li>Democracy</li>
                            <li>Fair elections</li>
                            <li>Rewards</li>
                            <li>Majority rule</li>
                        </ul>
                    </Typography>

                    <Typography variant="text2Regular">
                        You can tap to vote or use Telegram stars to vote. 50%
                        of the votes stars go on the prize fund of the
                        LuckySpin, and will be shared among 100 random
                        participants. Each vote is your LuckySpin ticket.
                    </Typography>
                    <Typography variant="text2Regular">
                        Moreover, you can add your own candidate even yourself.
                    </Typography>

                    <Typography variant="text2Regular">
                        Results of the election will be revealed on November 6,
                        2024.
                    </Typography>

                    <Typography variant="text2Bold">
                        Join the global vote in the Tap the system and help
                        choose the world`s president!
                    </Typography>
                </div>
            </div>
        </MainLayout>
    );
};

export default RulesScreen;
