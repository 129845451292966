import {
    FC,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from "react";

import { useAppDispatch } from "@hooks/useAppDispatch";

import FloatingText from "@atoms/FloatingText/FloatingText";
import Typography from "@atoms/Typography";

import { LightningSVG } from "@svg/LightningSVG";
import { SuccessBigSVG } from "@svg/SuccessBigSVG";

import { sendTaps } from "@redux/vote/vote.thunk";

import { calcPercentage } from "@utils/number/calcPercentage";

import styles from "../PointsInfo.module.scss";
import { ICircleTap } from "../PointsInfo.types";

interface FloatingTextData {
    id: number;
    x: number;
    y: number;
}

const CircleTap: FC<ICircleTap> = ({
    totalEnergy,
    currentEnergy,
    currentValue,
    totalValue,
    regeneration,
    onFinish,
}) => {
    const [rangeWidth, setRangeWidth] = useState(0);

    const [currentTmpEnergy, setCurrentTmpEnergy] = useState(
        currentEnergy || 0
    );
    const [currentTmpValue, setCurrentTmpValue] = useState(currentValue || 0);

    const container1Ref = useRef<HTMLDivElement | null>(null);
    const container2Ref = useRef<HTMLDivElement | null>(null);

    const rangeRef = useRef<HTMLDivElement>(null);

    const [floatingTexts, setFloatingTexts] = useState<FloatingTextData[]>([]);
    const [clickTimeout, setClickTimeout] = useState<any>();

    const [isRegenerating, setIsRegenerating] = useState(false);
    const [taps, setTaps] = useState(0);

    const dispatch = useAppDispatch();

    useEffect(() => {
        setCurrentTmpEnergy(currentEnergy!);
        setCurrentTmpValue(currentValue!);
    }, [currentEnergy, currentValue]);

    useEffect(() => {
        if (
            currentTmpEnergy === undefined ||
            regeneration === undefined ||
            totalEnergy === undefined
        )
            return;

        let regenInterval: NodeJS.Timeout;
        let regenTimeout: NodeJS.Timeout;

        if (currentTmpEnergy < totalEnergy && !isRegenerating) {
            regenTimeout = setTimeout(() => {
                setIsRegenerating(true);
            }, 1000);
        }

        if (isRegenerating && currentTmpEnergy < totalEnergy) {
            regenInterval = setInterval(() => {
                setCurrentTmpEnergy((prevEnergy) =>
                    Math.min(prevEnergy! + regeneration, totalEnergy)
                );
            }, 1000);
        }

        return () => {
            clearTimeout(regenTimeout);
            clearInterval(regenInterval);
        };
    }, [currentTmpEnergy, isRegenerating, regeneration, totalEnergy]);

    const handleClick = useCallback(
        () => {
            return;
            // if (currentTmpEnergy <= 0) {
            //     setCurrentTmpEnergy(0);
            //     return;
            // }

            // if (currentTmpEnergy === undefined || currentTmpValue === undefined)
            //     return;

            // const valueEnergy = currentTmpEnergy - 1;
            // setTaps(taps + 1);

            // setCurrentTmpEnergy(valueEnergy);
            // setCurrentTmpValue(currentTmpValue + 1);

            // if (currentTmpValue + 1 === totalValue) {
            //     clearTimeout(clickTimeout);
            //     // dispatch(sendTaps(taps + 1));
            //     onFinish();
            //     return;
            // }

            // clearTimeout(clickTimeout);
            // setClickTimeout(
            //     setTimeout(() => {
            //         // dispatch(sendTaps(taps + 1));
            //         setTaps(0);
            //     }, 500)
            // );

            // const chosenContainerRef =
            //     Math.random() < 0.5 ? container1Ref : container2Ref;

            // if (chosenContainerRef.current) {
            //     const container =
            //         chosenContainerRef.current.getBoundingClientRect();
            //     const randomX = Math.random() * container.width;
            //     const randomY = Math.random() * container.height;

            //     const newText = {
            //         id: Date.now() + Math.random(),
            //         x: container.left + randomX,
            //         y: container.top + randomY,
            //     };

            //     setFloatingTexts((prev) => [...prev, newText]);
            // }
        },
        [
            // clickTimeout,
            // currentTmpEnergy,
            // currentTmpValue,
            // dispatch,
            // onFinish,
            // taps,
            // totalValue,
        ]
    );

    useLayoutEffect(() => {
        const calculateRangeWidth = () => {
            if (rangeRef.current) {
                const element = rangeRef.current;
                const computedStyle = getComputedStyle(element);

                const width = element.clientWidth;

                const paddingLeft = parseFloat(computedStyle.paddingLeft);
                const paddingRight = parseFloat(computedStyle.paddingRight);

                const borderLeft = parseFloat(computedStyle.borderLeftWidth);
                const borderRight = parseFloat(computedStyle.borderRightWidth);

                const contentWidth =
                    width -
                    paddingLeft -
                    paddingRight -
                    borderLeft -
                    borderRight;
                setRangeWidth(contentWidth);
            }
        };

        calculateRangeWidth();

        window.addEventListener("resize", calculateRangeWidth);

        return () => {
            window.removeEventListener("resize", calculateRangeWidth);
        };
    }, []);

    const percent = calcPercentage(currentTmpEnergy!, totalEnergy!);

    return (
        <>
            <Typography className={styles.titleText} variant="textRegular">
                The voting is over
            </Typography>
            <div className={styles.circle} onClick={handleClick}>
                <SuccessBigSVG />
                {floatingTexts.map((text) => (
                    <FloatingText key={text.id} x={text.x} y={text.y} />
                ))}
            </div>

            <div ref={container1Ref} className={styles.container1}>
                1
            </div>
            <div ref={container2Ref} className={styles.container2}>
                2
            </div>
            <div className={styles.bottomBlock}>
                <div className={styles.progress}>
                    <Typography
                        className={styles.energyText}
                        variant="text3Regular"
                    >
                        {currentTmpEnergy?.toFixed(0)}
                    </Typography>
                    <LightningSVG className={styles.lightning} />
                    <div ref={rangeRef} className={styles.range}>
                        <div
                            className={styles.box}
                            style={{ width: `${percent}%` }}
                        >
                            <div
                                className={styles.line}
                                style={{ width: `${rangeWidth}px` }}
                            />
                        </div>
                    </div>
                </div>

                <div className={styles.info}>
                    <Typography variant="text3Regular">
                        Taps to Free Vote
                    </Typography>
                    <div className={styles.infoRange}>
                        <Typography variant="text3Regular">
                            {currentTmpValue?.toLocaleString()} /
                        </Typography>
                        <Typography variant="text3Bold">
                            {totalValue?.toLocaleString()}
                        </Typography>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CircleTap;
