import { FC } from "react";

import { ISvg } from "./SVG.types";

export const VoteSVG: FC<ISvg> = (props) => {
    return (
        <svg
            fill="none"
            height="20"
            viewBox="0 0 21 20"
            width="21"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M17.8438 3.75V16.25C17.8438 16.5401 17.7285 16.8183 17.5234 17.0234C17.3183 17.2285 17.0401 17.3438 16.75 17.3438H11.125C11.0007 17.3438 10.8815 17.2944 10.7935 17.2065C10.7056 17.1185 10.6562 16.9993 10.6562 16.875C10.6562 16.7507 10.7056 16.6315 10.7935 16.5435C10.8815 16.4556 11.0007 16.4062 11.125 16.4062H16.75C16.7914 16.4062 16.8312 16.3898 16.8605 16.3605C16.8898 16.3312 16.9062 16.2914 16.9062 16.25V3.75C16.9062 3.70856 16.8898 3.66882 16.8605 3.63951C16.8312 3.61021 16.7914 3.59375 16.75 3.59375H4.25C4.20856 3.59375 4.16882 3.61021 4.13951 3.63951C4.11021 3.66882 4.09375 3.70856 4.09375 3.75V11.25C4.09375 11.3743 4.04436 11.4935 3.95646 11.5815C3.86855 11.6694 3.74932 11.7188 3.625 11.7188C3.50068 11.7188 3.38145 11.6694 3.29354 11.5815C3.20564 11.4935 3.15625 11.3743 3.15625 11.25V3.75C3.15625 3.45992 3.27148 3.18172 3.4766 2.9766C3.68172 2.77148 3.95992 2.65625 4.25 2.65625H16.75C17.0401 2.65625 17.3183 2.77148 17.5234 2.9766C17.7285 3.18172 17.8438 3.45992 17.8438 3.75ZM9.54375 12.1687L5.5 16.2117L3.95625 14.6687C3.86739 14.5859 3.74986 14.5409 3.62842 14.543C3.50699 14.5452 3.39112 14.5944 3.30524 14.6802C3.21935 14.7661 3.17016 14.882 3.16802 15.0034C3.16587 15.1249 3.21095 15.2424 3.29375 15.3313L5.16875 17.2063C5.25664 17.294 5.37578 17.3433 5.5 17.3433C5.62422 17.3433 5.74336 17.294 5.83125 17.2063L10.2062 12.8313C10.289 12.7424 10.3341 12.6249 10.332 12.5034C10.3298 12.382 10.2806 12.2661 10.1948 12.1802C10.1089 12.0944 9.99301 12.0452 9.87158 12.043C9.75014 12.0409 9.63261 12.0859 9.54375 12.1687Z"
                fill="#696E85"
            />
        </svg>
    );
};
