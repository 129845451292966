import { FC } from "react";

import clsx from "clsx";

import { ITransparentBlock } from "./TransparentBlock.types";
import styles from "./transparent-block.module.scss";

const TransparentBlock: FC<ITransparentBlock> = ({
    children,
    rootClassName,
}) => {
    return (
        <div className={clsx(styles.transparent, rootClassName)}>
            {children}
        </div>
    );
};

export default TransparentBlock;
