import { createAsyncThunk } from "@reduxjs/toolkit";

import { CandidatesAPI } from "@services/endpoints/Candidates/Candidates";
import { TapsAPI } from "@services/endpoints/Taps/Taps";
import { VoteAPI } from "@services/endpoints/Vote/Vote";

export const fetchCandidatesList = createAsyncThunk(
    "vote/fetchCandidates",
    async () => {
        return (await CandidatesAPI.getPublicCandidatesList()).data;
    }
);

export const fetchTapsInfo = createAsyncThunk(
    "vote/fetchTapsInfo",
    async () => {
        return (await TapsAPI.getTapsInfo()).data;
    }
);

export const sendTaps = createAsyncThunk(
    "vote/sendTaps",
    async (taps: number) => {
        return (await TapsAPI.sendTaps({ taps })).data;
    }
);

export const sendVoteFree = createAsyncThunk(
    "vote/sendVoteFree",
    async (candidateId: number) => {
        return (await VoteAPI.sendFreeVote({ candidate_id: candidateId })).data;
    }
);

export const sendVoteStars = createAsyncThunk(
    "vote/sendVoteStars",
    async (candidateId: number) => {
        return (await VoteAPI.sendStarsVote({ candidate_id: candidateId }))
            .data;
    }
);
