import Typography from "@atoms/Typography";

import MainLayout from "@organisms/layouts/MainLayout";

import styles from "./privacy-policy.module.scss";

const PrivacyPolicyScreen = () => {
    return (
        <MainLayout
            withGoBack
            additionalScrollHeight={10}
            hasNavbar={false}
            title="Privacy Policy"
        >
            <div className={styles.wrapper}>
                <Typography variant="text2Regular">
                    Effective Date: 01.11.2024
                </Typography>
                <Typography variant="text2Regular">
                    This Privacy Policy outlines how Tap the System (the
                    “Service”) collects, uses, and protects the personal
                    information of users in accordance with the Privacy Policy
                    of the Telegram platform, which can be found{" "}
                    <a
                        href="https://telegram.org/privacy-tpa"
                        className={styles.link}
                    >
                        here
                    </a>
                    .
                </Typography>
                <Typography variant="text2Regular">
                    By using Tap the System, users consent to the collection,
                    processing, and storage of their data as described below.
                </Typography>

                <Typography variant="textBold">
                    1. Information Collected
                </Typography>
                <Typography variant="text2Bold">User Data</Typography>
                <Typography variant="text2Regular">
                    Upon authorization within the application, the Service
                    receives the following information from Telegram about the
                    user:
                </Typography>
                <Typography variant="text2Regular">
                    <ul className={styles.ul}>
                        <li>
                            Telegram ID: Service stores user`s Telegram ID to
                            identify user`s account
                        </li>
                        <li>
                            Telegram Username: Service stores user`s Telegram
                            Username to identify user`s account
                        </li>
                        <li>
                            Telegram First Name: Service stores user`s Telegram
                            First Name to make user`s account more personalized.
                        </li>
                        <li>
                            Telegram Last Name: Service stores user`s Telegram
                            Last Name to make user`s account more personalized.
                        </li>
                        <li>
                            Telegram User Language Code: Service stores user`s
                            Telegram User Language Code to make user`s account
                            more personalized.
                        </li>
                        <li>
                            Telegram Premium Status: Service stores user`s
                            Telegram Premium Status to make user`s account more
                            personalized.
                        </li>
                        <li>
                            Is bot: Service stores info about user`s status (is
                            bot: False/True) to identify user`s account
                        </li>
                    </ul>
                </Typography>
                <Typography variant="text2Bold">Candidate Data</Typography>
                <Typography variant="text2Regular">
                    When a user adds a candidate (themselves or another person)
                    to participate in the voting, they provide the following
                    information to the Service:
                </Typography>
                <Typography variant="text2Regular">
                    <ul className={styles.ul}>
                        <li>Candidate`s name</li>
                        <li>
                            Countries represented by the candidate (up to three)
                        </li>
                        <li>
                            Candidate`s photograph (processed by the Service to
                            match the app`s visual style in accordance with the
                            Candidate Addition Terms of the Service)
                        </li>
                    </ul>
                </Typography>
                <Typography variant="text2Bold">
                    Additional Acknowledgments
                </Typography>
                <Typography variant="text2Regular">
                    By submitting a candidate photograph, the user confirms and
                    guarantees that they have obtained all necessary permissions
                    from the individual depicted in the photograph and from the
                    photograph`s author for its use in this Service.
                </Typography>

                <Typography variant="textBold">
                    2. Use of Collected Information
                </Typography>
                <Typography variant="text2Regular">
                    The Service uses the collected information to:
                </Typography>
                <Typography variant="text2Regular">
                    <ul className={styles.ul}>
                        <li>
                            Display candidate data (name, countries represented,
                            and modified photo) in the Public Nominees list for
                            voting in the Service purposes
                        </li>
                        <li>
                            Process and style candidate photos in alignment with
                            the app`s visual design
                        </li>
                        <li>
                            Track user activity, manage referral bonuses, and
                            enable participation in app features (e.g., voting,
                            leaderboard)
                        </li>
                        <li>
                            Ensure the smooth operation and personalization of
                            the Service
                        </li>
                    </ul>
                </Typography>
                <Typography variant="textBold">
                    3. Data Storage and Retention
                </Typography>
                <Typography variant="text2Regular">
                    The data collected by the Service is stored securely and
                    retained as long as necessary to provide users with the
                    app`s functionalities or as required by applicable laws.
                </Typography>

                <Typography variant="textBold">
                    4. Compliance with Telegram Privacy Policy
                </Typography>
                <Typography variant="text2Regular">
                    Tap the System operates in accordance with{" "}
                    <a
                        href="https://telegram.org/privacy-tpa"
                        className={styles.link}
                    >
                        Telegram`s Privacy Policy
                    </a>
                    , including all standards related to data protection and
                    user consent.
                </Typography>

                <Typography variant="textBold">5. User Rights</Typography>
                <Typography variant="text2Regular">
                    Users have the right to:
                </Typography>
                <Typography variant="text2Regular">
                    <ul className={styles.ul}>
                        <li>Access the data the Service collects about them</li>
                        <li>
                            Request data correction or deletion if permissible
                            by the Service`s operational needs and legal
                            obligations
                        </li>
                    </ul>
                </Typography>
                <Typography variant="text2Regular">
                    To exercise these rights, users may contact the Service`s
                    support team.
                </Typography>

                <Typography variant="textBold">6. Data Sharing</Typography>
                <Typography variant="text2Regular">
                    The Service does not share, sell, or disclose user data to
                    third parties, except as necessary to comply with legal
                    obligations or protect the rights and security of the
                    Service and its users.
                </Typography>

                <Typography variant="textBold">
                    7. Changes to the Privacy Policy
                </Typography>
                <Typography variant="text2Regular">
                    The Service reserves the right to modify this Privacy Policy
                    at any time. Users will be notified of any significant
                    changes within the app.
                </Typography>

                <Typography variant="textBold">
                    8. Contact Information
                </Typography>
                <Typography variant="text2Regular">
                    For questions or concerns regarding this Privacy Policy,
                    please contact:{" "}
                    <span className={styles.link}>info@tapthesystem.com</span>
                </Typography>
            </div>
        </MainLayout>
    );
};

export default PrivacyPolicyScreen;
