import { AxiosPromise } from "axios";

import { API_URLS } from "@constants/api";

import { ICandidateCountry } from "@schemas/candidates";

import Request from "../../Request";

const url = API_URLS.countries;

export class CountriesAPI {
    static getCountiesList(): AxiosPromise<ICandidateCountry[]> {
        return Request.get(`${url}/list/`);
    }
}
