import { ChangeEvent, FC, useMemo, useState } from "react";

import { clsx } from "clsx";
import { v4 as uuidV4 } from "uuid";

import { InputError } from "@atoms/InputError/InputError";
import { InputLayout } from "@atoms/InputLayout/InputLayout";
import Typography from "@atoms/Typography";

import { ClipSvg } from "@svg/ClipSVG";
import { CloseSimpleSvg } from "@svg/CloseSimpleSVG";
import { FileSvg } from "@svg/FileSVG";

import { convertFileSizeToMB } from "@utils/size";

import styles from "./InputFile.module.scss";
import { IInputFile } from "./InputFile.types";

export const InputFile: FC<IInputFile> = ({
    onChange,
    value,
    className,
    placeholder,
    allowTypes,
    maxFileSizeMB,
    error: errorProps,
    title,
    subtitle,
    isError,
    disabled,
}) => {
    const [error, setError] = useState<string | null>(null);

    const uuid = useMemo(() => uuidV4(), []);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setError(null);

        const selectedFile = event.target.files?.[0];
        onChange(null);

        if (!selectedFile) {
            return;
        }

        if (allowTypes && !allowTypes.includes(selectedFile.type)) {
            setError("Invalid image format. Allowed formats: JPG, PNG, GIF.");
            return;
        }

        if (
            maxFileSizeMB &&
            convertFileSizeToMB(selectedFile.size) > maxFileSizeMB
        ) {
            setError(`The file must not exceed ${maxFileSizeMB}MB.`);
            return;
        }

        onChange(event);
    };

    const errorText = errorProps ?? error;

    return (
        <InputLayout
            className={clsx(styles.wrapper, className)}
            errorText={errorText}
            inputWrapperClassName={styles.block}
            isError={isError || !!error}
            subtitle={subtitle}
            title={title}
        >
            {value ? (
                <div className={styles.content}>
                    <div className={styles.box}>
                        <FileSvg />
                    </div>

                    <div className={styles.info}>
                        <Typography
                            className={styles.name}
                            variant="text2Regular"
                        >
                            {value.name}
                        </Typography>
                        <Typography
                            className={styles.size}
                            variant="text2Regular"
                        >
                            {convertFileSizeToMB(value.size).toFixed(2)} MB
                        </Typography>
                    </div>

                    <button
                        className={styles.close}
                        disabled={disabled}
                        onClick={() => onChange(null)}
                    >
                        <CloseSimpleSvg />
                    </button>
                </div>
            ) : (
                <label className={styles.label} htmlFor={uuid}>
                    <Typography variant="text2Regular">
                        {placeholder ?? "Upload file"}
                    </Typography>
                    <input
                        className={styles.input}
                        disabled={disabled}
                        id={uuid}
                        type="file"
                        onChange={handleChange}
                    />
                    <ClipSvg />
                </label>
            )}
        </InputLayout>
    );
};
