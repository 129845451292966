import { FC } from "react";

import { clsx } from "clsx";

import TransparentBlock from "@atoms/TransparentBlock/TransparentBlock";
import Typography from "@atoms/Typography";

import styles from "./SpinInfo.module.scss";
import { ISpinInfo } from "./SpinInfo.types";

export const SpinInfo: FC<ISpinInfo> = ({ rows, className }) => {
    return (
        <TransparentBlock rootClassName={clsx(styles.wrapper, className)}>
            {rows.map((row, index) => {
                if (typeof row === "string") {
                    return (
                        <Typography
                            key={index}
                            className={styles.subtitle}
                            variant="textSmall"
                        >
                            {row}
                        </Typography>
                    );
                }

                const { iconBlock } = row;

                return (
                    <div key={index} className={styles.row}>
                        {(iconBlock || row.emptyIconBlock) && (
                            <div className={styles.icon}>{iconBlock}</div>
                        )}

                        <Typography
                            className={styles.title}
                            variant="text3Regular"
                        >
                            {row.title}
                        </Typography>

                        <Typography
                            className={styles.value}
                            variant="text3Bold"
                        >
                            {row.value}
                        </Typography>
                    </div>
                );
            })}
        </TransparentBlock>
    );
};
