import { routes } from "@constants/routes";

import { NomiSVG } from "@svg/NomiSVG";
import { RulesSVG } from "@svg/RulesSVG";
import { TasksSVG } from "@svg/TasksSVG";
import { VoteSVG } from "@svg/VoteSVG";

import { INavItem } from "./Navbar.types";

export const navItems: INavItem[] = [
    {
        icon: VoteSVG,
        path: routes.vote,
        title: "Vote",
    },
    {
        icon: NomiSVG,
        path: routes.nomi,
        title: "Nomi",
    },
    {
        icon: TasksSVG,
        path: routes.tasks,
        title: "Tasks",
    },
    {
        icon: RulesSVG,
        path: routes.govern,
        title: "Govern",
    },
] as const;

export const navActiveItemGradient = ["#FFB392", "#FF7134"];
