import { FC, memo, useState } from "react";

import Button from "@atoms/Button";
import TransparentBlock from "@atoms/TransparentBlock/TransparentBlock";
import Typography from "@atoms/Typography";

import Modal from "@molecules/Modal";

import { VoteCard } from "@screens/VoteScreen/components/VoteCard/VoteCard";

import { getCorrectClientDate } from "@utils/date/getCorrectClientDate";

import { ICandidateCard } from "../CandidateBlock.types";
import styles from "../candidates-block.module.scss";
import StatusTag from "./StatusTag";

const statusesForPreview = ["accepted", "published"];

const CandidateCard: FC<ICandidateCard> = ({
    candidate,
    onShowReason,
    onPay,
    id,
    isPayLoading,
}) => {
    const [isOpenCard, setIsOpenCard] = useState(false);

    return (
        <TransparentBlock rootClassName={styles.card}>
            <Modal
                open={isOpenCard}
                title="Candidate's card preview"
                onClose={() => setIsOpenCard(false)}
            >
                <div className={styles.modalWrapper}>
                    <VoteCard
                        flags={candidate.countries.map(({ photo }) => photo)}
                        id={candidate.id}
                        name={candidate.name}
                        percent={50}
                        photo={candidate.photo}
                        votesAmount={1000}
                        onVote={() => null}
                    />
                </div>
            </Modal>

            <Typography className={styles.titleText} variant="text2Bold">
                {candidate.name}
            </Typography>

            <div className={styles.row}>
                <Typography variant="text3Regular">#</Typography>
                <Typography variant="text3Bold">{id}</Typography>
            </div>

            <div className={styles.row}>
                <Typography variant="text3Regular">Date</Typography>
                <Typography variant="text3Bold">
                    {getCorrectClientDate(new Date(candidate.created_at))}
                </Typography>
            </div>

            <div className={styles.row}>
                <Typography variant="text3Regular">Citizenship</Typography>
                <div className={styles.imgContainer}>
                    {candidate.countries.map(({ photo }, index) => (
                        <img key={index} alt="#" src={photo} />
                    ))}
                </div>
            </div>

            <div className={styles.row}>
                <Typography variant="text3Regular">Status</Typography>

                <button
                    onClick={() =>
                        candidate.status === "deleted" &&
                        candidate.delete_reason &&
                        onShowReason(candidate.delete_reason)
                    }
                >
                    <StatusTag status={candidate.status} />
                </button>
            </div>

            <div className={styles.buttonContainer}>
                <Button
                    disabled={!statusesForPreview.includes(candidate.status)}
                    variant="halfTransparent"
                    onClick={() => {
                        if (statusesForPreview.includes(candidate.status))
                            setIsOpenCard(true);
                    }}
                >
                    <Typography variant="text3Bold">Card Preview</Typography>
                </Button>
                <Button
                    disabled={candidate.status !== "accepted" || isPayLoading}
                    onClick={() => onPay(candidate.id)}
                >
                    <Typography variant="text3Bold">Pay</Typography>
                </Button>
            </div>
        </TransparentBlock>
    );
};

export default memo(CandidateCard);
