import { createAsyncThunk } from "@reduxjs/toolkit";

import { AuthAPI } from "@services/endpoints/Auth/Auth";
import { ISignInDto } from "@services/endpoints/Auth/dto/sign-in.dto";
import { ClientsAPI } from "@services/endpoints/Clients/Clients";

export const fetchUserToken = createAsyncThunk(
    "user/singIn",
    async (dto: ISignInDto) => {
        return (await AuthAPI.signIn(dto)).data;
    }
);

export const fetchUserInfo = createAsyncThunk("user/info", async () => {
    return (await ClientsAPI.getUserInfo()).data;
});
