import clsx from "clsx";

import Typography from "@atoms/Typography";

import styles from "./InputError.module.scss";
import { IInputError } from "./InputError.types";

export const InputError: FCC<IInputError> = ({ className, children }) => {
    return (
        <Typography
            className={clsx(styles.wrapper, className)}
            variant="desktopBodyS"
        >
            {children}
        </Typography>
    );
};
