import { FC, memo } from "react";

import clsx from "clsx";

import Button from "@atoms/Button";
import TransparentBlock from "@atoms/TransparentBlock/TransparentBlock";
import Typography from "@atoms/Typography";

import { isUndefined } from "@utils/isUndefined";
import { Notifications } from "@utils/notifications";

import { voteCardPositions } from "./VoteCard.const";
import styles from "./VoteCard.module.scss";
import { IVoteCard } from "./VoteCard.types";

const VoteCardComponent: FC<IVoteCard> = ({
    flags,
    name,
    percent,
    votesAmount,
    position,
    className,
    photo,
    onVote,
    id,
}) => {
    const PositionSVG = position ? voteCardPositions[position] : null;

    return (
        <TransparentBlock rootClassName={clsx(styles.wrapper, className)}>
            {PositionSVG && <PositionSVG className={styles.position} />}

            <div className={styles.person}>
                <img alt="" className={styles.photo} src={photo} />

                {!isUndefined(percent) && (
                    <Typography
                        className={styles.percentage}
                        variant="text3Semibold"
                    >
                        {percent.toString().replace(".", ",")} %
                    </Typography>
                )}

                {!!flags.length && (
                    <div className={styles.flags}>
                        {flags.map((imgPath, index) => (
                            <img
                                key={index}
                                alt="flag"
                                className={styles.flag}
                                src={imgPath}
                            />
                        ))}
                    </div>
                )}
            </div>

            <div className={styles.info}>
                <Typography variant="text3Regular">{name}</Typography>

                {!isUndefined(votesAmount) && (
                    <Typography
                        className={styles.votes}
                        variant="text3Semibold"
                    >
                        <Typography
                            className={styles.votes}
                            variant="text3Semibold"
                        >
                            Votes
                        </Typography>
                        <Typography
                            className={styles.votes}
                            variant="text3Semibold"
                        >
                            {votesAmount.toLocaleString("Ru-ru")}
                        </Typography>
                    </Typography>
                )}
            </div>

            {onVote && (
                <Button
                    className={styles.btn}
                    variant="standard"
                    onClick={() => {
                        onVote(id);
                    }}
                >
                    <Typography variant="text3Bold">Vote</Typography>
                </Button>
            )}
        </TransparentBlock>
    );
};

export const VoteCard = memo(VoteCardComponent);
