import Typography from "@atoms/Typography";

import MainLayout from "@organisms/layouts/MainLayout";

import styles from "./addition-terms.module.scss";

const CandidateAdditionTermsScreen = () => {
    return (
        <MainLayout
            withGoBack
            additionalScrollHeight={10}
            hasNavbar={false}
            title="Candidate Addition Terms"
        >
            <div className={styles.wrapper}>
                <Typography variant="text2Regular">
                    Effective Date: 01.11.2024
                </Typography>
                <Typography variant="text2Bold">
                    1. Candidate Addition Process for Public Nominees List
                </Typography>
                <Typography variant="text2Regular">
                    To add a candidate, users must complete the following steps:
                </Typography>
                <Typography variant="text2Regular">
                    a) Application Submission: Enter the candidate`s name and up
                    to three countries they represent in the application form.
                </Typography>
                <Typography variant="text2Regular">
                    b) Application Status Tracking: Successfully submitted
                    applications appear in the “Your Applications” list,
                    allowing users to track application status. Submitted
                    applications then undergo moderation by the service.
                </Typography>
                <Typography variant="text2Regular">
                    c) Approval and Payment: Once approved, users can preview
                    the final candidate card before publication. If satisfied,
                    they may proceed with the payment of 35,000 Telegram stars
                    to publish the candidate card to the Public Nominees list.
                </Typography>

                <Typography variant="text2Regular">
                    Application Statuses:
                    <ul className={styles.ul}>
                        <li>Moderation - Under review.</li>
                        <li>
                            Accepted - Approved, and the candidate card is ready
                            for publication.
                        </li>
                        <li>
                            Rejected - Application for candidate publication was
                            declined.
                        </li>
                        <li>
                            Deleted - The candidate card has been removed from
                            the Public Nominees list.
                        </li>
                    </ul>
                </Typography>

                <Typography variant="text2Bold">
                    2. Handling of User-Submitted Data
                </Typography>
                <Typography variant="text2Regular">
                    Candidate data provided by the user, including the
                    candidate`s name, countries represented, and photo, will be
                    processed by the service in accordance with the service`s
                    Privacy Policy.
                </Typography>

                <Typography variant="text2Bold">
                    3. Intellectual Property Compliance
                </Typography>
                <Typography variant="text2Regular">
                    By submitting a candidate`s name, country data, and photo,
                    the user confirms that neither they nor any third parties
                    will raise claims against the service regarding copyright
                    infringement. Should any claims arise, the service reserves
                    the right to hold the submitting user responsible.
                </Typography>

                <Typography variant="text2Bold">
                    4. Resolution of Third-Party Claims
                </Typography>
                <Typography variant="text2Regular">
                    If the service receives claims regarding third-party rights
                    infringement, the service may delete the related data
                    without any possibility of restoration or requirement to
                    provide a reason (for example, if a user adds the name,
                    countries, and photo of a person who does not consent to the
                    use of their information).
                </Typography>

                <Typography variant="text2Bold">
                    5. Right to Decline Applications
                </Typography>
                <Typography variant="text2Regular">
                    The service reserves the right to reject any candidate
                    application without explanation. If rejected, the
                    application will show a “Rejected” status in the “Your
                    Applications” list.
                </Typography>

                <Typography variant="text2Bold">
                    6. Voting Threshold and Removal from Public Nominees List
                </Typography>
                <Typography variant="text2Regular">
                    After successful publication, if the candidate does not
                    reach 0.1% of votes within 60 minutes, they will be removed
                    from the Public Nominees list. If the threshold is met, the
                    candidate remains in the list. Re-adding after removal a
                    candidate requires repeating all steps from the beginning. A
                    candidate in Public Nominees may advance to the Nominees
                    list if they enter the top three candidates.
                </Typography>

                <Typography variant="text2Bold">7. Refund Policy</Typography>
                <Typography variant="text2Regular">
                    In the event that a candidate is removed from the Public
                    Nominees list, the Service will not issue a refund for the
                    publication fee.
                </Typography>

                <Typography variant="text2Bold">
                    8. Explanation of Candidate Deletion
                </Typography>
                <Typography variant="text2Regular">
                    The reason for the removal of a candidate from the Public
                    Nominee list can be viewed in the app by the user who
                    submitted the candidate`s application, upon logging in with
                    their Telegram account. The Service will make reasonable
                    efforts to provide more detailed explanations for the
                    deletion of a candidate if requested by the user; however,
                    the Service does not guarantee a detailed explanation.
                </Typography>
            </div>
        </MainLayout>
    );
};

export default CandidateAdditionTermsScreen;
