import { AxiosPromise } from "axios";

import { API_URLS } from "@constants/api";

import { ITokens } from "@schemas/auth";

import Request from "../../Request";
import { ISignInDto } from "./dto/sign-in.dto";

const url = API_URLS.auth;

export class AuthAPI {
    static signIn(dto: ISignInDto): AxiosPromise<ITokens> {
        return Request.post(`${url}/`, dto);
    }
}
