import Button from "@atoms/Button";
import Typography from "@atoms/Typography";

import MainLayout from "@organisms/layouts/MainLayout";

import wifioops from "../../../assets/images/WifiOops.png";
import styles from "./oops-screen.module.scss";

const OopsScreen = () => {
    return (
        <MainLayout additionalScrollHeight={10} hasNavbar={false} title="">
            <div className={styles.wrapper}>
                <img alt="wifi" src={wifioops} />
                <Typography variant="h1Bold">
                    OOPS!
                    <br />
                    Something went wrong
                </Typography>
                <Typography className={styles.descText} variant="textRegular">
                    Make sure you have a stable internet connection and restart
                    the app.
                </Typography>

                <Button className={styles.restartBtn}>Restart</Button>
            </div>
        </MainLayout>
    );
};

export default OopsScreen;
