import { createSlice } from "@reduxjs/toolkit";

import { ICandidateCountry } from "@schemas/candidates";

import { fetchCountries } from "./common.thunk";

export interface ICommonState {
    isLoading: boolean;
    countries: ICandidateCountry[] | null;
}

const initialState: ICommonState = {
    isLoading: false,
    countries: null,
};

export const commonSlice = createSlice({
    initialState,
    name: "common",
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCountries.fulfilled, (state, { payload }) => {
                state.countries = payload;
                state.isLoading = false;
            })
            .addCase(fetchCountries.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchCountries.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

export const commonActions = { ...commonSlice.actions };

export const commonReducer = commonSlice.reducer;
