import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IApplicationFormFields } from "@screens/ApplicationFormScreen/ApplicationFormScreen.types";

import { IMyCandidate } from "@schemas/candidates";

import { fetchMyCandidatesList } from "./nomi.thunk";

export interface INomiState {
    isCandidatesLoading: boolean;
    candidates: IMyCandidate[] | null;
    applicationCandidateFormFields: IApplicationFormFields | null;
}

const initialState: INomiState = {
    candidates: null,
    isCandidatesLoading: false,
    applicationCandidateFormFields: null,
};

export const nomiSlice = createSlice({
    initialState,
    name: "nomi",
    reducers: {
        setApplicationCandidateFormFields(
            state,
            { payload }: PayloadAction<IApplicationFormFields>
        ) {
            state.applicationCandidateFormFields = payload;
        },
        clearApplicationCandidateFormFields(state) {
            state.applicationCandidateFormFields = null;
        },
        addCandidateToBeginning(
            state,
            { payload }: PayloadAction<IMyCandidate>
        ) {
            state.candidates = [payload, ...(state.candidates ?? [])];
        },
        changeCandidateStatus(
            state,
            { payload }: PayloadAction<Pick<IMyCandidate, "id" | "status">>
        ) {
            const { id, status } = payload;

            state.candidates?.forEach((candidate) => {
                if (candidate.id === id) {
                    candidate.status = status;
                }
            });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMyCandidatesList.fulfilled, (state, { payload }) => {
                state.candidates = payload;
                state.isCandidatesLoading = false;
            })
            .addCase(fetchMyCandidatesList.pending, (state) => {
                state.isCandidatesLoading = true;
            })
            .addCase(fetchMyCandidatesList.rejected, (state) => {
                state.isCandidatesLoading = false;
            });
    },
});

export const nomiActions = { ...nomiSlice.actions };

export const nomiReducer = nomiSlice.reducer;
