import { FC } from "react";

import { ISvg } from "./SVG.types";

export const CloseSimpleSvg: FC<ISvg> = (props) => {
    return (
        <svg
            fill="none"
            height="16"
            viewBox="0 0 16 16"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M12.854 12.1462C12.9005 12.1927 12.9373 12.2478 12.9625 12.3085C12.9876 12.3692 13.0006 12.4343 13.0006 12.5C13.0006 12.5657 12.9876 12.6307 12.9625 12.6914C12.9373 12.7521 12.9005 12.8073 12.854 12.8537C12.8076 12.9002 12.7524 12.937 12.6917 12.9622C12.631 12.9873 12.566 13.0003 12.5003 13.0003C12.4346 13.0003 12.3695 12.9873 12.3088 12.9622C12.2481 12.937 12.193 12.9002 12.1465 12.8537L8.00028 8.70685L3.85403 12.8537C3.76021 12.9475 3.63296 13.0003 3.50028 13.0003C3.3676 13.0003 3.24035 12.9475 3.14653 12.8537C3.05271 12.7599 3 12.6327 3 12.5C3 12.3673 3.05271 12.24 3.14653 12.1462L7.2934 7.99997L3.14653 3.85372C3.05271 3.7599 3 3.63265 3 3.49997C3 3.36729 3.05271 3.24004 3.14653 3.14622C3.24035 3.0524 3.3676 2.99969 3.50028 2.99969C3.63296 2.99969 3.76021 3.0524 3.85403 3.14622L8.00028 7.2931L12.1465 3.14622C12.2403 3.0524 12.3676 2.99969 12.5003 2.99969C12.633 2.99969 12.7602 3.0524 12.854 3.14622C12.9478 3.24004 13.0006 3.36729 13.0006 3.49997C13.0006 3.63265 12.9478 3.7599 12.854 3.85372L8.70715 7.99997L12.854 12.1462Z"
                fill="white"
            />
        </svg>
    );
};
