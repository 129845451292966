import { FC, useCallback, useEffect, useState } from "react";

import { clsx } from "clsx";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";

import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";

import Button from "@atoms/Button";
import IconBlock from "@atoms/IconBlock";
import Typography from "@atoms/Typography";

import Modal from "@molecules/Modal";

import { ArrowRightSVG } from "@svg/ArrowRigthSVG";
import { TicketSVG } from "@svg/TicketSVG";

import { userInfoSelector } from "@redux/user/user.selectors";
import { userActions } from "@redux/user/user.slice";
import { fetchUserInfo } from "@redux/user/user.thunk";
import { voteActions } from "@redux/vote/vote.slice";
import {
    fetchCandidatesList,
    sendVoteFree,
    sendVoteStars,
} from "@redux/vote/vote.thunk";

import { Notifications } from "@utils/notifications";

import { invoice } from "@telegram-apps/sdk";

import { VoteCard } from "../VoteCard/VoteCard";
import { TVoteCardPosition } from "../VoteCard/VoteCard.types";
import { slidesPerView } from "./VoteNominees.const";
import styles from "./VoteNominees.module.scss";
import { IVoteNominees, TNomineesVoteCard } from "./VoteNominees.types";

export const VoteNominees: FC<IVoteNominees> = ({
    title,
    className,
    cards,
    withPositions,
}) => {
    const [slider, setSlider] = useState<SwiperClass | null>(null);
    const [canSlidePrev, setCanSlidePrev] = useState(!slider?.isBeginning);
    const [canSlideNext, setCanSlideNext] = useState(!slider?.isEnd);
    const [selectedCard, setSelectedCard] = useState<TNomineesVoteCard | null>(
        null
    );

    const dispatch = useAppDispatch();

    const { userInfo } = useAppSelector(userInfoSelector);

    const changeSlideAllows = useCallback((slider: SwiperClass) => {
        setCanSlidePrev(!slider?.isBeginning);
        setCanSlideNext(!slider?.isEnd);
    }, []);

    useEffect(() => {
        if (slider) {
            changeSlideAllows(slider);
        }
    }, [changeSlideAllows, slider]);

    const handleChangeSlide = (swiper: SwiperClass) => {
        changeSlideAllows(swiper);
    };

    const handleVoteFree = () => {
        const selectedId = selectedCard?.id;

        if (!selectedId) return;

        dispatch(sendVoteFree(selectedId)).then((data: any) => {
            if (data.error) {
                Notifications.createError("Not enough free votes");
                return;
            }

            Notifications.createSuccess(
                "Congrats! Your vote has been counted. You`ve earned 1 extra PreLuckySpin ticket"
            );

            dispatch(fetchCandidatesList());
            dispatch(fetchUserInfo());
        });
    };

    const handleVoteStars = () => {
        const selectedId = selectedCard?.id;

        if (!selectedId) return;

        dispatch(sendVoteStars(selectedId)).then(async ({ payload }: any) => {
            if (!payload.url) {
                Notifications.createError("Something is wrong");
                return;
            }

            const status = await invoice.open(payload.url, "url");
            if (status === "paid") {
                Notifications.createSuccess(
                    "Congrats! Your vote has been counted. You`ve earned 1 extra LuckySpin ticket"
                );

                dispatch(userActions.incrementLuckySpinTiket());
                dispatch(voteActions.incrementVoteToCandidate(selectedId));
                setSelectedCard(null);
            }
        });
    };

    const handleClickCardBtn = useCallback(
        (currentId: number) => {
            const currentCard = cards.find(({ id }) => id === currentId);

            if (!currentCard) {
                return;
            }

            setSelectedCard(currentCard);
        },
        [cards]
    );

    return (
        <div className={clsx(styles.wrapper, className)}>
            <Modal
                open={!!selectedCard}
                title="Cast your vote"
                onClose={() => setSelectedCard(null)}
            >
                <div className={styles.cardContainer}>
                    {selectedCard && (
                        <VoteCard
                            {...selectedCard}
                            className={styles.cardModal}
                        />
                    )}
                </div>
                <div className={styles.btnContainer}>
                    <div className={styles.btnLabel}>
                        <Button
                            className={styles.btn}
                            disabled={userInfo?.votes_free === 0}
                            onClick={handleVoteFree}
                        >
                            Free vote
                        </Button>
                        <div className={styles.label}>
                            <IconBlock
                                borderRadius="small"
                                mode="gray"
                                size="small"
                            >
                                <TicketSVG />
                            </IconBlock>
                            <Typography variant="text3Bold">+1</Typography>
                            <Typography variant="text3Regular">
                                PreLuckySpin Ticket
                            </Typography>
                        </div>
                    </div>
                    <div className={styles.btnLabel}>
                        <Button
                            className={styles.btn}
                            onClick={handleVoteStars}
                        >
                            Vote for Stars
                        </Button>
                        <div className={styles.label}>
                            <IconBlock
                                borderRadius="small"
                                mode="red"
                                size="small"
                            >
                                <TicketSVG />
                            </IconBlock>
                            <Typography variant="text3Bold">+1</Typography>
                            <Typography variant="text3Regular">
                                LuckySpin Ticket
                            </Typography>
                        </div>
                    </div>
                </div>
            </Modal>

            <div className={styles.top}>
                <Typography className={styles.textMargin} variant="textBold">
                    {title}
                </Typography>

                <div className={styles.arrows}>
                    <button
                        disabled={!canSlidePrev}
                        onClick={() => slider?.slidePrev()}
                    >
                        <ArrowRightSVG
                            className={clsx(styles.arrow, styles.arrow_left, {
                                [styles.arrow_disabled]: !canSlidePrev,
                            })}
                            height={20}
                            width={20}
                        />
                    </button>

                    <button
                        disabled={!canSlideNext}
                        onClick={() => slider?.slideNext()}
                    >
                        <ArrowRightSVG
                            className={clsx(styles.arrow, styles.arrow_right, {
                                [styles.arrow_disabled]: !canSlideNext,
                            })}
                            height={20}
                            width={20}
                        />
                    </button>
                </div>
            </div>

            <div className={styles.cards}>
                <Swiper
                    slidesPerView={slidesPerView}
                    spaceBetween={12}
                    onSlideChange={handleChangeSlide}
                    onSwiper={setSlider}
                >
                    {cards.map((data, index) => (
                        <SwiperSlide key={index}>
                            <VoteCard
                                {...data}
                                className={clsx(styles.card)}
                                position={
                                    withPositions && index < 3
                                        ? (String(
                                              index + 1
                                          ) as TVoteCardPosition)
                                        : undefined
                                }
                                onVote={handleClickCardBtn}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
};
