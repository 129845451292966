import { createAsyncThunk } from "@reduxjs/toolkit";

import { CandidatesAPI } from "@services/endpoints/Candidates/Candidates";

export const fetchMyCandidatesList = createAsyncThunk(
    "vote/fetchMyCandidates",
    async () => {
        return (await CandidatesAPI.getMyCandidatesList()).data;
    }
);
