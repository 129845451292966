export const routes = {
    vote: "/vote",
    nomi: "/nomi",
    rules: "/rules",
    tasks: "/tasks",
    leaderboard: "/tasks/leaderboard",
    start: "/",
    applicationForm: "/nomi/application",
    oops: "/oops",
    heavyLoad: "/heavyload",
    govern: "/govern",
    additionTerms: "/addition-terms",
    privacyPolicy: "/privacy-policy",
    termsCondition: "/terms-condition",
} as const;

export type TAppRoute = (typeof routes)[keyof typeof routes];
