import { useCallback, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { CandidatesAPI } from "@services/endpoints/Candidates/Candidates";

import { routes } from "@constants/routes";

import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";

import Button from "@atoms/Button";
import SunSpinner from "@atoms/SunSpinner";
import Typography from "@atoms/Typography";

import MainLayout from "@organisms/layouts/MainLayout";

import { ArrowDownSVG } from "@svg/ArrowDownSVG";
import { ArrowTopSVG } from "@svg/ArrowTopSVG";
import { StarSVG } from "@svg/StarSVG";

import { constantsSelector } from "@redux/constants/constants.selectors";
import { fetchConstants } from "@redux/constants/constants.thunk";
import { nomiSelector } from "@redux/nomi/nomi.selectors";
import { nomiActions } from "@redux/nomi/nomi.slice";
import { fetchMyCandidatesList } from "@redux/nomi/nomi.thunk";
import { fetchCandidatesList } from "@redux/vote/vote.thunk";

import { Notifications } from "@utils/notifications";

import { invoice } from "@telegram-apps/sdk";

import CandidatesBlock from "./CandidatesBlock/CandidatesBlock";
import ExpandBlock from "./ExpandBlock/ExpandBlock";
import styles from "./nomi-screen.module.scss";

const NomiScreen = () => {
    const navigate = useNavigate();

    const constants = useAppSelector(constantsSelector);
    const { candidates, isCandidatesLoading } = useAppSelector(nomiSelector);

    const [isOpen, setIsOpen] = useState(true);
    const [isPayLoading, seTisPayLoading] = useState(false);

    const dispatch = useAppDispatch();

    const handlePayNomi = useCallback(
        async (candidateId: number) => {
            seTisPayLoading(true);

            try {
                const nomiUrl = (
                    await CandidatesAPI.publishCandidate({
                        candidate_id: candidateId,
                    })
                ).data.url;

                if (nomiUrl) {
                    const status = await invoice.open(nomiUrl, "url");
                    if (status === "paid") {
                        Notifications.createSuccess(
                            "Stars have been successfully credited! Your candidate is now in the Public Nominees list."
                        );
                        dispatch(
                            nomiActions.changeCandidateStatus({
                                id: candidateId,
                                status: "published",
                            })
                        );
                    }
                }
            } catch (e) {
                console.error(e);
            } finally {
                seTisPayLoading(false);
            }
        },
        [dispatch]
    );

    useEffect(() => {
        if (!candidates?.length) {
            dispatch(fetchMyCandidatesList());
        }
    }, [candidates?.length, dispatch]);

    useEffect(() => {
        dispatch(fetchConstants("publish_candidate"));
    }, [dispatch]);

    return (
        <MainLayout additionalScrollHeight={10} title="Public Nominee">
            <div className={styles.wrapper}>
                <div className={styles.addBlock}>
                    <Typography
                        className={styles.addText}
                        variant="textRegular"
                    >
                        Add yourself or other person as a nominee
                    </Typography>
                    <div className={styles.starBlock}>
                        <Typography variant="textRegular">
                            {constants.data[0].value}
                        </Typography>
                        <StarSVG />
                    </div>
                </div>

                <div
                    className={styles.collapseButton}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <Typography variant="textBold">
                        Steps to add a candidate to the Public Nominee
                    </Typography>
                    {isOpen ? <ArrowTopSVG /> : <ArrowDownSVG />}
                </div>

                {isOpen && <ExpandBlock />}

                <Button
                    className={styles.addButton}
                    onClick={() => navigate(routes.applicationForm)}
                >
                    Apply Now
                </Button>

                {isCandidatesLoading ? (
                    <SunSpinner horizontalCentered />
                ) : (
                    <CandidatesBlock
                        candidates={candidates ?? []}
                        isPayLoading={isPayLoading}
                        onPay={handlePayNomi}
                    />
                )}
            </div>
        </MainLayout>
    );
};

export default NomiScreen;
