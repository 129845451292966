import { createSlice } from "@reduxjs/toolkit";

import { TNomineesVoteCard } from "@screens/VoteScreen/components/VoteNominees/VoteNominees.types";

import { ITapsInfo } from "@schemas/taps";

import { fetchCandidatesList, fetchTapsInfo, sendTaps } from "./vote.thunk";
import { mapCandidatesToVoteCard } from "./vote.utils";

export interface IVoteState {
    isCandidateLoading: boolean;
    isTapsLoading: boolean;
    myCandidates: TNomineesVoteCard[] | null;
    publicCandidates: TNomineesVoteCard[] | null;
    taps: ITapsInfo | null;
}

const initialState: IVoteState = {
    isCandidateLoading: false,
    isTapsLoading: false,
    myCandidates: null,
    publicCandidates: null,
    taps: null,
};

export const voteSlice = createSlice({
    initialState,
    name: "vote",
    reducers: {
        setMyCandidates: (state, { payload }) => {
            state.myCandidates = payload;
        },
        setPublicCandidates: (state, { payload }) => {
            state.publicCandidates = payload;
        },
        setTaps: (state, { payload }) => {
            state.taps = payload;
        },
        incrementVoteToCandidate: (state, { payload }) => {
            const candidate = state.myCandidates?.find(
                (el) => el.id === payload
            );

            if (candidate) {
                candidate.votesAmount!++;
            } else {
                const publicCandidate = state.publicCandidates?.find(
                    (el) => el.id === payload
                );

                if (publicCandidate) publicCandidate.votesAmount!++;
            }
        },
        setIsCandidatesLoading: (state, { payload }) => {
            state.isCandidateLoading = payload;
        },
        setIsTapsLoading: (state, { payload }) => {
            state.isTapsLoading = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCandidatesList.pending, (state) => {
                state.isCandidateLoading = true;
            })
            .addCase(fetchCandidatesList.fulfilled, (state, { payload }) => {
                const { leaders, public_nomines, nomines } = payload;

                state.publicCandidates = mapCandidatesToVoteCard(
                    leaders.concat(nomines)
                );
                state.myCandidates = mapCandidatesToVoteCard(public_nomines);
                state.isCandidateLoading = false;
            })
            .addCase(fetchCandidatesList.rejected, (state) => {
                state.isCandidateLoading = false;
            });

        builder
            .addCase(fetchTapsInfo.pending, (state) => {
                state.isTapsLoading = true;
            })
            .addCase(fetchTapsInfo.fulfilled, (state, { payload }) => {
                state.taps = payload;
                state.isTapsLoading = false;
            })
            .addCase(fetchTapsInfo.rejected, (state) => {
                state.isTapsLoading = false;
            });
    },
});

export const voteActions = voteSlice.actions;

export const voteReducer = voteSlice.reducer;
