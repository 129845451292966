import { FC } from "react";

import { ISvg } from "./SVG.types";

export const SecondPositionSVG: FC<ISvg> = (props) => {
    return (
        <svg
            fill="none"
            height="16"
            viewBox="0 0 16 16"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M16 8.00005C16 3.58176 12.4182 0 8 0C3.58178 0 0 3.58176 0 8.00005C0 12.4182 3.58178 16 8 16C12.4182 16 16 12.4182 16 8.00005Z"
                fill="#8DA4C7"
            />
            <path
                d="M7.9993 0.666138C4.29367 0.666138 1.2793 3.95564 1.2793 7.99947C1.2793 12.0433 4.29367 15.3328 7.9993 15.3328C11.7049 15.3328 14.7193 12.0433 14.7193 7.99947C14.7193 3.95564 11.7049 0.666138 7.9993 0.666138Z"
                fill="#7490B9"
            />
            <path
                d="M14.7193 8.00076C14.7193 4.32466 11.7051 1.33386 7.99957 1.33386C6.94864 1.33386 5.95342 1.57413 5.06698 2.00266C4.14201 2.44953 3.33537 3.10044 2.70684 3.89667C2.41997 4.25998 2.17015 4.65358 1.963 5.07217C1.52562 5.95642 1.2793 6.95077 1.2793 8.00076C1.2793 8.77956 1.41473 9.52699 1.66298 10.2222C2.41096 12.3164 4.18559 13.9322 6.38327 14.4722C6.90101 14.5995 7.44266 14.6672 7.99957 14.6672C9.23899 14.6672 10.4008 14.3328 11.3986 13.7501C11.8435 13.4898 12.2558 13.1807 12.628 12.8294C13.3298 12.1671 13.8876 11.3564 14.2497 10.4482C14.5527 9.69026 14.7193 8.86467 14.7193 8.00076Z"
                fill="#8DA4C7"
            />
            <path
                d="M11.5193 13.7103L1.97115 4.66614C1.52855 5.58771 1.2793 6.62402 1.2793 7.71833C1.2793 8.53 1.41635 9.30898 1.66756 10.0336L6.44414 14.4629C6.96806 14.5956 7.51617 14.6661 8.07972 14.6661C9.33393 14.6661 10.5096 14.3176 11.5193 13.7103Z"
                fill="#ABBBD4"
            />
            <path
                d="M14.0831 10.3207L5.42773 2C4.55588 2.44026 3.79557 3.08156 3.20312 3.86601L12.5545 12.6667C13.216 12.0141 13.7417 11.2155 14.0831 10.3207Z"
                fill="#ABBBD4"
            />
            <path
                d="M9.9999 11.23H5.09165V10.1404L7.41339 8.11397C7.77999 7.78811 8.06172 7.47923 8.25859 7.18731C8.46225 6.89539 8.56408 6.58651 8.56408 6.26065V6.14863C8.56408 5.77525 8.46225 5.49013 8.25859 5.29325C8.05493 5.08959 7.75962 4.98776 7.37266 4.98776C6.97892 4.98776 6.67342 5.09299 6.45618 5.30344C6.24573 5.51389 6.09299 5.78204 5.99794 6.1079L5 5.73113C5.06789 5.5071 5.16632 5.29325 5.29531 5.08959C5.4243 4.87914 5.58723 4.69245 5.7841 4.52952C5.98776 4.36659 6.22537 4.23761 6.49692 4.14256C6.77525 4.04752 7.09432 4 7.45413 4C7.82072 4 8.14658 4.05431 8.4317 4.16293C8.72362 4.26476 8.96801 4.40732 9.16489 4.59062C9.36176 4.77392 9.51111 4.99455 9.61294 5.25252C9.71477 5.5037 9.76569 5.78204 9.76569 6.08754C9.76569 6.37266 9.72156 6.63742 9.63331 6.88182C9.55185 7.11942 9.43304 7.35024 9.2769 7.57427C9.12755 7.79151 8.94765 8.00196 8.7372 8.20562C8.52675 8.40928 8.29593 8.61294 8.04475 8.81661L6.3238 10.2626H9.9999V11.23Z"
                fill="white"
            />
        </svg>
    );
};
