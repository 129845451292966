import { useNavigate } from "react-router-dom";

import { routes } from "@constants/routes";

import IconBlock from "@atoms/IconBlock";
import TransparentBlock from "@atoms/TransparentBlock/TransparentBlock";
import Typography from "@atoms/Typography";

import styles from "../nomi-screen.module.scss";

const ExpandBlock = () => {
    const navigate = useNavigate();

    return (
        <div className={styles.collapseExpand}>
            <TransparentBlock rootClassName={styles.expandBlock}>
                <IconBlock className={styles.expandIcon} mode="gray">
                    1
                </IconBlock>
                <div className={styles.textBlock}>
                    <Typography variant="text2Bold">
                        Submit Application
                    </Typography>
                    <Typography variant="text3Regular">
                        Fill out and submit the application form. By submitting,
                        you agree to the{" "}
                        <span
                            style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                            }}
                            onClick={() => navigate(routes.additionTerms)}
                        >
                            Candidate Addition Terms.
                        </span>
                    </Typography>
                </div>
            </TransparentBlock>
            <TransparentBlock rootClassName={styles.expandBlock}>
                <IconBlock className={styles.expandIcon} mode="gray">
                    2
                </IconBlock>
                <div className={styles.textBlock}>
                    <Typography variant="text2Bold">Moderation</Typography>
                    <Typography variant="text3Regular">
                        Once submitted, your application goes to moderation. You
                        can track its status in your application list.
                    </Typography>
                </div>
            </TransparentBlock>
            <TransparentBlock rootClassName={styles.expandBlock}>
                <IconBlock className={styles.expandIcon} mode="gray">
                    3
                </IconBlock>
                <div className={styles.textBlock}>
                    <Typography variant="text2Bold">
                        Approval & Payment
                    </Typography>
                    <Typography variant="text3Regular">
                        If your application approved, proceed with payment of
                        50,000 Telegram Stars to add your candidate. Once paid,
                        your candidate will appear in the Public Nominees list
                        on the main screen.
                    </Typography>
                </div>
            </TransparentBlock>
        </div>
    );
};

export default ExpandBlock;
