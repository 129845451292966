import { AxiosPromise } from "axios";

import { API_URLS } from "@constants/api";

import { ITapsInfo, IUserTapsInfo } from "@schemas/taps";

import Request from "../../Request";
import { ISendTapsDto } from "./dto/send-taps.dto";

const url = API_URLS.taps;

export class TapsAPI {
    static getTapsInfo(): AxiosPromise<ITapsInfo> {
        return Request.get(`${url}/info/`);
    }

    static sendTaps(dto: ISendTapsDto): AxiosPromise<IUserTapsInfo> {
        return Request.post(`${url}/tap/`, dto);
    }
}
