import { createAsyncThunk } from "@reduxjs/toolkit";

import { ConstantsAPI } from "@services/endpoints/Constants/Constants";

import { IConstantKey } from "./constants.slice";

export const fetchConstants = createAsyncThunk(
    "constants/getConstants",
    async (dto: IConstantKey) => {
        return (await ConstantsAPI.getConstants(dto)).data;
    }
);
