import { useLocation } from "react-router-dom";

import {
    hideStartScreenValue,
    isShowStartScreensKey,
} from "@constants/localStorage";
import { TAppRoute, routes } from "@constants/routes";

export const useSkipStartScreen = () => {
    const location = useLocation();

    const showStart = localStorage.getItem(isShowStartScreensKey);
    const pathname = location.pathname as TAppRoute;

    const skipStartScreen =
        showStart === hideStartScreenValue && pathname === routes.start;

    return { skipStartScreen };
};
