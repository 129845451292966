import { ChangeEvent, FC, Fragment, useEffect, useMemo, useState } from "react";

import clsx from "clsx";

import Input from "@atoms/Input/Input";
import { InputLayout } from "@atoms/InputLayout/InputLayout";

import { ArrowDownSVG } from "@svg/ArrowDownSVG";

import { isString } from "@utils/isString";

import styles from "./Select.module.scss";
import { ISelect, ISelectSearch } from "./Select.types";

export const Select: FC<ISelect> = ({
    options,
    value: valueProps,
    className,
    error,
    subtitle,
    title,
    selectsAmount = 1,
    placeholder,
    onChange,
    disabled,
    onSelectClose,
    onSelectOpen,
}) => {
    const [isFocused, setIsFocused] = useState(
        [...Array(selectsAmount)].map(() => false)
    );
    const [search, setSearch] = useState<ISelectSearch | null>();

    const handleChangeFocus = (currentIndex: number, value: boolean) => {
        setSearch(value ? { index: currentIndex, value: "" } : null);

        setIsFocused((prev) => {
            return prev.map((oldValue, index) =>
                currentIndex === index ? value : oldValue
            );
        });
    };

    useEffect(() => {
        const isAnyFocused = isFocused.some((value) => !!value);

        isAnyFocused ? onSelectOpen?.() : onSelectClose?.();
    }, [isFocused, onSelectClose, onSelectOpen]);

    const handleSearch = (
        index: number,
        e: ChangeEvent<HTMLInputElement> | string
    ) => {
        const value = isString(e) ? e : e.target.value;

        setSearch({ index, value });
    };

    const handleSelectOption = (index: number, value: string | number) => {
        onChange?.(index, value);
    };

    const filteredOptionsBySearch = useMemo(
        () =>
            search
                ? options.filter(
                      ({ label }) =>
                          label
                              .toLowerCase()
                              .indexOf(search.value.toLowerCase()) > -1
                  )
                : options,
        [options, search]
    );

    return (
        <InputLayout
            className={clsx(styles.wrapper, className)}
            errorText={error}
            inputWrapperClassName={styles.block}
            subtitle={subtitle}
            title={title}
        >
            {[...Array(selectsAmount)].map((_, index) => {
                const currentValue = valueProps[index];

                const currentPlaceholder = Array.isArray(placeholder)
                    ? placeholder[index]
                    : placeholder;

                const open = isFocused[index];

                const currentLabel = options.find(
                    ({ value }) => String(currentValue) === String(value)
                )?.label;

                const inputValue =
                    search?.index === index ? search.value : currentLabel;

                return (
                    <div
                        key={index}
                        className={styles.container}
                        tabIndex={-1}
                        onBlur={() => {
                            handleChangeFocus(index, false);
                        }}
                        onClick={() => {
                            !disabled && handleChangeFocus(index, !open);
                        }}
                    >
                        <Input
                            className={styles.input}
                            isFocused={open}
                            placeholder={currentPlaceholder}
                            rightIcon={
                                <ArrowDownSVG
                                    className={clsx(styles.arrow, {
                                        [styles.arrow_active]: open,
                                    })}
                                />
                            }
                            value={inputValue ?? ""}
                            onChange={(e) => handleSearch(index, e)}
                        />

                        <div
                            className={clsx(styles.options, {
                                [styles.options_active]: open,
                            })}
                        >
                            {filteredOptionsBySearch.map(
                                ({ label, value, className, icon }) => (
                                    <div
                                        key={value}
                                        className={clsx(
                                            styles.option,
                                            className,
                                            {
                                                [styles.option_active]:
                                                    String(currentValue) ===
                                                    String(value),
                                            }
                                        )}
                                        // Важно не onClick, т.к. не будет выбираться значение
                                        onMouseDown={() =>
                                            handleSelectOption(index, value)
                                        }
                                    >
                                        {!!icon && (
                                            <div className={styles.icon}>
                                                {icon}
                                            </div>
                                        )}

                                        {label}
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                );
            })}
        </InputLayout>
    );
};
