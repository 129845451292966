import Typography from "@atoms/Typography";

import MainLayout from "@organisms/layouts/MainLayout";

import styles from "./terms-condition.module.scss";

const TermsConditionScreen = () => {
    return (
        <MainLayout
            withGoBack
            additionalScrollHeight={10}
            hasNavbar={false}
            title="Terms & Conditions"
        >
            <div className={styles.wrapper}>
                <Typography variant="text2Regular">
                    Effective Date: 01.11.2024
                </Typography>

                <Typography variant="text2Bold">
                    Welcome to Tap the System
                </Typography>
                <Typography variant="text2Regular">
                    Tap the System is a Telegram mini-app where users worldwide
                    can vote for a global leader, nominate candidates, and
                    participate in prize draws for rewards, including Telegram
                    Stars.
                </Typography>
                <Typography variant="text2Regular">
                    By accessing or using Tap the System, you agree to these
                    Terms & Conditions. Please read them carefully.
                </Typography>

                <Typography variant="textBold">
                    1. Introduction and Overview
                </Typography>
                <Typography variant="text2Regular">
                    Tap the System presents a global voting platform where users
                    can:
                </Typography>
                <Typography variant="text2Regular">
                    <ul className={styles.ul}>
                        <li>
                            Vote for candidates from a list of politicians,
                            influencers, and user-nominated Public Nominees.
                        </li>
                        <li>
                            Add their own candidates to the Public Nominees
                            list.
                        </li>
                        <li>
                            Participate in prize draws (PreLuckySpin and
                            LuckySpin) with the chance to win Telegram Stars.
                        </li>
                    </ul>
                </Typography>
                <Typography variant="text2Regular">
                    These Terms govern your use of the app, including voting,
                    candidate nomination, and prize participation.
                </Typography>

                <Typography variant="textBold">
                    2. Eligibility and Access
                </Typography>
                <Typography variant="text2Regular">
                    To access Tap the System, users must have an active Telegram
                    account and adhere to{" "}
                    <a
                        href="https://telegram.org/privacy-tpa"
                        className={styles.link}
                    >
                        Telegram`s Privacy Policy
                    </a>{" "}
                    and
                    <a
                        href="https://telegram.org/tos/mini-apps"
                        className={styles.link}
                    >
                        Terms of Service.
                    </a>
                </Typography>
                <Typography variant="text2Regular">
                    If you experience access issues due to weak internet or high
                    server demand, you will be notified through an in-app
                    service message.
                </Typography>

                <Typography variant="textBold">3. Voting Rules</Typography>
                <Typography variant="textBold">Voting Types</Typography>

                <Typography variant="text2Regular">
                    Users have multiple ways to cast votes:
                </Typography>
                <Typography variant="text2Regular">
                    <ul className={styles.ul}>
                        <li>
                            Vote: Users may vote using Telegram Stars (1 Vote =
                            50 Stars).
                        </li>
                        <li>
                            1 Free Vote per day from taps: One Free Vote is
                            available per day through taps or by inviting
                            friends (1 Friend = 50 taps).
                        </li>
                        <li>
                            Free Votes from referal program: Users earn
                            additional Free Votes by inviting friends (10
                            friends = 1 Free Vote).
                        </li>
                    </ul>
                </Typography>
                <Typography variant="text2Regular">
                    Each Free Vote grants 1 ticket to the PreLuckySpin prize
                    draw, and each Vote grants 1 ticket to the LuckySpin main
                    prize draw.
                </Typography>

                <Typography variant="textBold">
                    Vote Counts and Ticket Rules
                </Typography>

                <Typography variant="text2Regular">
                    <ul className={styles.ul}>
                        <li>
                            PreLuckySpin Ticket: 1 Free Vote = 1 PreLuckySpin
                            ticket.
                        </li>
                        <li>LuckySpin Ticket: 1 Vote = 1 LuckySpin ticket.</li>
                    </ul>
                </Typography>

                <Typography variant="text2Regular">
                    Tickets increase users chances of winning in the respective
                    prize draws. Votes cast using Telegram Stars contribute to
                    the LuckySpin prize fund. Initial fund and additional 50% of
                    collected Telegram Stars for Votes will be distributed among
                    100 random participants.
                </Typography>

                <Typography variant="textBold">
                    4.Candidate Nomination Process
                </Typography>
                <Typography variant="text2Regular">
                    Users may nominate themselves or others as candidates in the
                    global vote.
                </Typography>

                <Typography variant="textBold">
                    Steps to Nominate a Candidate
                </Typography>
                <Typography variant="text2Regular">
                    <ul className={styles.ul}>
                        <li>
                            Application Submission: Complete the form with the
                            candidate`s name, up to three representing
                            countries, and a photo. By submitting Application
                            form, users agree to the Candidate Addition Terms.
                        </li>
                        <li>
                            Moderation: Submitted applications are reviewed and
                            can be tracked in “Your Applications.”
                        </li>
                        <li>
                            Approval and Publishing: Upon approval, users can
                            preview the candidate`s card and publish it with
                            35,000 Telegram Stars to add the candidate to the
                            Public Nominees list.
                        </li>
                    </ul>
                </Typography>

                <Typography variant="textBold">
                    Public Nominees List Rules
                </Typography>

                <Typography variant="text2Regular">
                    If a Public Nominee candidate does not reach 0.1% of votes
                    within 60 minutes, Service reserves the right to remove this
                    candidate from the list. Users may reapply from the start if
                    removed. Candidates in Public Nominees can advance to the
                    Nominees list if they reach the top three by vote count.
                </Typography>

                <Typography variant="textBold">No Refund Policy</Typography>
                <Typography variant="text2Regular">
                    Payments made for votes or candidate nominations are
                    non-refundable under any circumstances, including candidate
                    removal from the Public Nominees list.
                </Typography>

                <Typography variant="textBold">
                    5. PreLuckySpin and LuckySpin
                </Typography>
                <Typography variant="textBold">PreLuckySpin Draw</Typography>
                <Typography variant="text2Regular">
                    <ul className={styles.ul}>
                        <li>Period: November 7, 2024 - November 28, 2024</li>
                        <li>Prize: Users may win free LuckySpin tickets.</li>
                        <li>
                            Draw Mechanics: Users who cast Free Votes can win a
                            ticket to the main LuckySpin. Each Free Vote
                            increases the chance of winning, but only 1 ticket
                            per user may be awarded.
                        </li>
                    </ul>
                </Typography>

                <Typography variant="textBold">LuckySpin Main Draw</Typography>
                <Typography variant="text2Regular">
                    <ul className={styles.ul}>
                        <li>Date: November 29, 2024</li>
                        <li>
                            Prize Pool: Telegram Stars shared among 100 winners,
                            with LuckySpin fund.
                        </li>
                        <li>
                            Draw Mechanics: Winners are selected randomly, with
                            each ticket increasing winning odds. Each user may
                            win only 1 prize in LuckySpin.
                        </li>
                    </ul>
                </Typography>

                <Typography variant="textBold">
                    PreLuckySpin and LuckySpin Rules
                </Typography>

                <Typography variant="text2Regular">
                    The initial LuckySpin fund at the start of the game is
                    20,000 Telegram Stars. This fund will grow throughout the
                    game as 50% of all Star payments for votes are added to it.
                    The Service reserves the right to further increase the
                    LuckySpin fund with additional Telegram Stars.
                </Typography>

                <Typography variant="text2Regular">
                    If the number of PreLuckySpin participants is fewer than
                    1,000 users, the Service may choose to hold the PreLuckySpin
                    as a one-time event and adjust the number of LuckySpin
                    ticket prizes to 10% of the total PreLuckySpin participants.
                </Typography>

                <Typography variant="text2Regular">
                    If the PreLuckySpin participation is under 1,000 users, the
                    Service also reserves the right to hold the LuckySpin event
                    early. The number of prizes in LuckySpin will remain
                    unchanged.
                </Typography>

                <Typography variant="text2Regular">
                    Prize values may be in increments of 100, 200, 250, 300,
                    400, or 500 Telegram Stars, depending on the LuckySpin Fund
                    balance at the end of the game. If there is any remaining
                    balance in the LuckySpin Fund, it will be rounded up to the
                    nearest increment (100, 200, 250, 300, 400, or 500 Telegram
                    Stars) and distributed among all game participants in an
                    additional prize draw. There can be only one winner in the
                    additional prize draw.
                </Typography>

                <Typography variant="text2Regular">
                    If fewer than 100 users have cast votes for candidates using
                    Telegram Stars within the Service by the end of General
                    Voting, the Service reserves the right to cancel both the
                    PreLuckySpin and LuckySpin events. In such a case, users
                    will be notified of the Service&apos;s decision through the
                    official channel at{" "}
                    <a
                        className={styles.link}
                        href="https://t.me/TapTheSystemOfficial"
                    >
                        https://t.me/TapTheSystemOfficial
                    </a>
                    .
                </Typography>

                <Typography variant="textBold">
                    6. Tasks and Additional Rewards
                </Typography>
                <Typography variant="text2Regular">
                    Users can complete in-app tasks for additional taps and
                    votes:
                </Typography>
                <Typography variant="text2Regular">
                    <ul className={styles.ul}>
                        <li>
                            Subscription Task: Gain 1,000 taps by subscribing to
                            the Tap the System channel.
                        </li>
                        <li>
                            Referral Task: Invite friends to receive extra taps
                            (50 taps per friend) and Free Votes (1 Free Vote for
                            every 10 friends).
                        </li>
                    </ul>
                </Typography>

                <Typography variant="textBold">
                    7. Data Collection and Privacy
                </Typography>

                <Typography variant="text2Regular">
                    In compliance with Telegram`s Privacy Policy, Tap the System
                    collects certain user data upon authorization, including:
                    Telegram ID, Telegram Username, Telegram First Name,
                    Telegram User Language Code, Telegram Premium Status.
                </Typography>
                <Typography variant="text2Regular">
                    Candidate data (name, country representation, and photo) is
                    also collected for display purposes. By submitting a
                    candidate photo, users confirm they have obtained all
                    necessary permissions from the individual depicted and the
                    photo`s author.
                </Typography>
                <Typography variant="text2Regular">
                    For further information, please refer to Tap the System
                    Privacy Policy.
                </Typography>

                <Typography variant="textBold">8. Users Deletions</Typography>
                <Typography variant="textBold">Candidate Removal</Typography>
                <Typography variant="text2Regular">
                    If a candidate`s submission violates any Terms or receives a
                    rights complaint, Tap the System reserves the right to
                    remove that candidate without notification or restoration.
                </Typography>

                <Typography variant="textBold">User Removal</Typography>
                <Typography variant="text2Regular">
                    Service reserves the right to remove any user from the
                    Telegram mini app “Tap the System” if the user violates the
                    rules or engages in behavior that damages the reputation of
                    the Service. Actions deemed inappropriate by the Service are
                    subject to internal assessment, and the Service is not
                    obligated to provide explanations for any removal decisions.
                </Typography>
                <Typography variant="text2Regular">
                    By using Tap the System, users agree to comply with all
                    terms and acknowledge that failure to do so may result in
                    permanent removal from the platform without prior notice or
                    explanation.
                </Typography>

                <Typography variant="textBold">Explanation Requests</Typography>
                <Typography variant="text2Regular">
                    The Service will attempt to provide reasons for candidate
                    removal and user removal upon request, though it does not
                    guarantee the provision of detailed explanations.
                </Typography>
                <Typography variant="text2Regular">
                    For further information, please refer to Tap the System
                    Candidate Addition Terms.
                </Typography>

                <Typography variant="textBold">
                    9. Intellectual Property and User Responsibilities
                </Typography>
                <Typography variant="text2Regular">
                    By submitting content, including candidate data and photos,
                    users agree to:
                </Typography>

                <Typography variant="text2Regular">
                    <ul className={styles.ul}>
                        <li>
                            Take responsibility for any third-party claims
                            regarding copyright infringement.
                        </li>
                        <li>
                            Ensure that all submissions comply with the app`s
                            terms, including the acquisition of necessary
                            permissions from photographed individuals and photo
                            owners.
                        </li>
                    </ul>
                </Typography>
                <Typography variant="text2Regular">
                    For further information, please refer to Tap the System
                    Candidate Addition Terms.
                </Typography>

                <Typography variant="textBold">
                    10. Changes to Terms & Conditions
                </Typography>
                <Typography variant="text2Regular">
                    Service reserves the right to update these Terms &
                    Conditions at any time. Notifications of significant changes
                    will be posted within the app.
                </Typography>

                <Typography variant="textBold">
                    11. Contact Information
                </Typography>
                <Typography variant="text2Regular">
                    For questions or concerns regarding these Terms &
                    Conditions, please contact{" "}
                    <span className={styles.link}>info@tapthesystem.com</span>
                </Typography>
            </div>
        </MainLayout>
    );
};

export default TermsConditionScreen;
