import { FC } from "react";

import { FirstPositionSVG } from "@svg/FirstPositionSVG";
import { ISvg } from "@svg/SVG.types";
import { SecondPositionSVG } from "@svg/SecondPositionSVG";
import { ThirdPositionSVG } from "@svg/ThirdPositionSVG";

import { TVoteCardPosition } from "./VoteCard.types";

export const voteCardPositions: Record<TVoteCardPosition, FC<ISvg>> = {
    "1": FirstPositionSVG,
    "2": SecondPositionSVG,
    "3": ThirdPositionSVG,
} as const;
