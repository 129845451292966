import { createAsyncThunk } from "@reduxjs/toolkit";

import { TasksAPI } from "@services/endpoints/Tasks/Tasks";

export const fetchTasks = createAsyncThunk("tasks/all", async () => {
    return (await TasksAPI.getTasks()).data;
});

export const sendCheckTelegramFollow = createAsyncThunk(
    "tasks/check/telegram/",
    async (taskId: number) => {
        return (await TasksAPI.checkFollowOnChannel(taskId)).data;
    }
);

export const sendClaimTask = createAsyncThunk(
    "tasks/claim/",
    async (taskId: number) => {
        return (await TasksAPI.claimTask(taskId)).data;
    }
);
