import { FC } from "react";

import { ISvg } from "./SVG.types";

export const ArrowTopSVG: FC<ISvg> = (props) => {
    return (
        <svg
            fill="none"
            height="20"
            viewBox="0 0 20 20"
            width="20"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.30746 12.0578L9.55747 5.80783C9.61551 5.74972 9.68444 5.70362 9.76032 5.67217C9.83619 5.64071 9.91752 5.62453 9.99965 5.62453C10.0818 5.62453 10.1631 5.64072 10.239 5.67217C10.3149 5.70362 10.3838 5.74972 10.4418 5.80783L16.6918 12.0578C16.8091 12.1751 16.875 12.3342 16.875 12.5C16.875 12.6659 16.8091 12.8249 16.6918 12.9422C16.5746 13.0595 16.4155 13.1254 16.2497 13.1254C16.0838 13.1254 15.9247 13.0595 15.8075 12.9422L9.99965 7.13361L4.19184 12.9422C4.13377 13.0003 4.06483 13.0463 3.98896 13.0778C3.91309 13.1092 3.83177 13.1254 3.74965 13.1254C3.66753 13.1254 3.58621 13.1092 3.51034 13.0778C3.43447 13.0463 3.36553 13.0003 3.30746 12.9422C3.2494 12.8841 3.20333 12.8152 3.17191 12.7393C3.14048 12.6635 3.1243 12.5821 3.1243 12.5C3.1243 12.4179 3.14048 12.3366 3.17191 12.2607C3.20333 12.1848 3.2494 12.1159 3.30746 12.0578Z"
                fill="white"
            />
        </svg>
    );
};
