import React, { useEffect, useState } from "react";

import Typography from "@atoms/Typography";

import { CountdownProps } from "./CircleCountdown.types";
import styles from "./circle-countdown.module.scss";

export const CircleCountdown: React.FC<CountdownProps> = ({
    hours,
    minutes,
    seconds,
    totalTaps,
    unrealizedTaps,
}) => {
    const [timeLeft, setTimeLeft] = useState(
        hours * 3600 + minutes * 60 + seconds
    );

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    const formatTime = (time: number) => String(time).padStart(2, "0");
    const currentHours = Math.floor(timeLeft / 3600);
    const currentMinutes = Math.floor((timeLeft % 3600) / 60);
    const currentSeconds = timeLeft % 60;

    const radius = 54;
    const circumference = 2 * Math.PI * radius;
    const progress =
        ((hours * 3600 + minutes * 60 + seconds - timeLeft) /
            (hours * 3600 + minutes * 60 + seconds)) *
        circumference;

    return (
        <div className={styles.wrapper}>
            <Typography variant="textRegular">
                1 Day - 1 Free Vote for taps
            </Typography>
            <svg className={styles.circleContainer}>
                <circle className={styles.outBorder} cx="62" cy="62" r={62} />
                <circle
                    className={styles.circleProgress}
                    cx="138"
                    cy="62"
                    r={54}
                    strokeDasharray={circumference}
                    strokeDashoffset={circumference - progress}
                    transform="rotate(-90 100 100)"
                />
            </svg>
            <div className={styles.textContainer}>
                <Typography variant="text3Regular">Next attempt in</Typography>

                <Typography variant="textRegular">
                    {formatTime(currentHours)}:{formatTime(currentMinutes)}:
                    {formatTime(currentSeconds)}
                </Typography>

                <div className={styles.textInfo}>
                    <Typography className={styles.info} variant="text3Regular">
                        hh
                    </Typography>
                    <Typography className={styles.info} variant="text3Regular">
                        mm
                    </Typography>
                    <Typography className={styles.info} variant="text3Regular">
                        ss
                    </Typography>
                </div>
            </div>
            <Typography variant="text2Regular">
                Get Free Vote by inviting frens
            </Typography>
            <div className={styles.bottomBlock}>
                <div className={styles.info}>
                    <div className={styles.totalTaps}>
                        <Typography variant="text3Regular">
                            Taps in reserve
                        </Typography>
                        <div className={styles.infoTotal}>
                            <Typography variant="text3Regular">
                                + {unrealizedTaps}
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className={styles.info}>
                    <div className={styles.totalTaps}>
                        <Typography variant="text3Regular">
                            Taps to Free Vote
                        </Typography>
                        <div className={styles.infoTotal}>
                            <Typography variant="text3Regular">
                                {" "}
                                {totalTaps} /
                            </Typography>
                            <Typography variant="text3Bold">
                                {totalTaps}
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
