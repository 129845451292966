import { FC } from "react";

import Button from "@atoms/Button";
import IconBlock from "@atoms/IconBlock";
import TransparentBlock from "@atoms/TransparentBlock/TransparentBlock";
import Typography from "@atoms/Typography";

import { IInfoBlock } from "./InfoBlock.types";
import styles from "./info-block.module.scss";

const InfoBlock: FC<IInfoBlock> = ({
    icon,
    textTitle,
    textDesc,
    btnStatus,
    buttonText,
    claimCount,
    onClick,
}) => {
    return (
        <TransparentBlock rootClassName={styles.cardContainer}>
            <IconBlock
                borderRadius="big"
                className={styles.cardIcon}
                mode="gray"
            >
                {icon}
            </IconBlock>

            <div className={styles.cardText}>
                <Typography variant="text2Bold">{textTitle}</Typography>
                <Typography variant="text2Regular">{textDesc}</Typography>
            </div>

            <Button
                className={styles.cardBtn}
                disabled={btnStatus === "disabled"}
                variant="standard"
            >
                <Typography variant="text2Bold" onClick={onClick}>
                    {buttonText} {claimCount !== null ? `(${claimCount})` : ""}
                </Typography>
            </Button>
        </TransparentBlock>
    );
};

export default InfoBlock;
