import { useEffect } from "react";

import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "swiper/css";

import { TAppRoute, routes } from "@constants/routes";

import { useAppSelector } from "@hooks/useAppSelector";
import { useAuthUser } from "@hooks/useAuthUser";
import { useClearNomiForm } from "@hooks/useClearNomiForm";
import { useHandleAppErrors } from "@hooks/useHandleAppErrors";
import { useSkipStartScreen } from "@hooks/useSkpiStartScreen";

import ToasterComponent from "@molecules/ToasterComponent";

import ApplicationFormScreen from "@screens/ApplicationFormScreen/ApplicationFormScreen";
import CandidateAdditionTermsScreen from "@screens/CandidateAdditionTermsScreen/CandidateAdditionTermsScreen";
import GovernScreen from "@screens/GovernScreen/GovernScreen";
import HeavyLoadScreen from "@screens/HeavyLoadScreen/HeavyLoadScreen";
import LeaderboardScreen from "@screens/LeaderboardScreen";
import LoadingScreen from "@screens/LoadingScreen";
import NomiScreen from "@screens/NomiScreen";
import OopsScreen from "@screens/OopsScreen/OopsScreen";
import PrivacyPolicyScreen from "@screens/PrivacyPolicyScreen/PrivacyPolicyScreen";
import RulesScreen from "@screens/RulesScreen";
import StartScreen from "@screens/StartScreen";
import TasksScreen from "@screens/TasksScreen";
import TermsConditionScreen from "@screens/TermsConditionScreen/TermsConditionScreen";
import VoteScreen from "@screens/VoteScreen";

import { isAuthSelector } from "@redux/user/user.selectors";

import { init } from "@telegram-apps/sdk";

import "./App.css";

init();

const App = () => {
    const navigate = useNavigate();

    const isAuth = useAppSelector(isAuthSelector);

    useAuthUser();
    useHandleAppErrors();
    useClearNomiForm();
    const { skipStartScreen } = useSkipStartScreen();

    if (skipStartScreen) {
        navigate(routes.vote);
        return null;
    }

    return (
        <>
            <ToasterComponent />
            <Routes>
                <Route
                    element={isAuth ? <VoteScreen /> : <LoadingScreen />}
                    path={routes.vote}
                />

                {isAuth && (
                    <>
                        <Route element={<NomiScreen />} path={routes.nomi} />
                        <Route element={<TasksScreen />} path={routes.tasks} />
                        <Route element={<RulesScreen />} path={routes.rules} />

                        <Route
                            element={<CandidateAdditionTermsScreen />}
                            path={routes.additionTerms}
                        />
                        <Route
                            element={<PrivacyPolicyScreen />}
                            path={routes.privacyPolicy}
                        />
                        <Route
                            element={<TermsConditionScreen />}
                            path={routes.termsCondition}
                        />

                        <Route
                            element={<GovernScreen />}
                            path={routes.govern}
                        />
                        <Route
                            element={<ApplicationFormScreen />}
                            path={routes.applicationForm}
                        />
                        <Route
                            element={<LeaderboardScreen />}
                            path={routes.leaderboard}
                        />
                        <Route element={<TasksScreen />} path={routes.tasks} />
                    </>
                )}

                <Route element={<OopsScreen />} path={routes.oops} />
                <Route element={<HeavyLoadScreen />} path={routes.heavyLoad} />
                <Route element={<StartScreen />} path={routes.start} />
                <Route element={<StartScreen />} path="*" />
            </Routes>
        </>
    );
};

export default App;
