import {
    ChangeEvent,
    ChangeEventHandler,
    useCallback,
    useEffect,
    useState,
} from "react";

import clsx from "clsx";
import { useNavigate } from "react-router-dom";

import { CandidatesAPI } from "@services/endpoints/Candidates/Candidates";

import { TAppRoute, routes } from "@constants/routes";

import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";
import { useIsIos } from "@hooks/useIsIos";

import Button from "@atoms/Button";
import Input from "@atoms/Input/Input";
import { InputFile } from "@atoms/InputFile/InputFile";
import { Select } from "@atoms/Select/Select";
import SunSpinner from "@atoms/SunSpinner";
import Typography from "@atoms/Typography";

import MainLayout from "@organisms/layouts/MainLayout";

import { commonSelector } from "@redux/common/common.selectors";
import { fetchCountries } from "@redux/common/common.thunk";
import { applicationCandidateFormFieldsSelector } from "@redux/nomi/nomi.selectors";
import { nomiActions } from "@redux/nomi/nomi.slice";

import { isString } from "@utils/isString";
import { Notifications } from "@utils/notifications";

import {
    allowTypes,
    applicationFormDefaultErrors,
    applicationFormDefaultValues,
    maxFileSizeMB,
} from "./ApplicationFormScreen.const";
import {
    IApplicationFormFields,
    TApplicationFormFieldsErrors,
} from "./ApplicationFormScreen.types";
import styles from "./application-form.module.scss";

const ApplicationFormScreen = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const cachedFields = useAppSelector(applicationCandidateFormFieldsSelector);

    const isIOS = useIsIos();

    const { countries, isLoading: isCountriesLoading } =
        useAppSelector(commonSelector);

    const [isLoading, setIsLoading] = useState(false);
    const [fields, setFields] = useState<IApplicationFormFields>(
        cachedFields ?? applicationFormDefaultValues
    );
    const [fieldErrors, setFieldErrors] =
        useState<TApplicationFormFieldsErrors>(applicationFormDefaultErrors);
    const [isAnySelectOpen, setIsAnySelectOpen] = useState(false);

    const handleChangeFieldValue = <K extends keyof IApplicationFormFields>(
        fieldName: K,
        value: IApplicationFormFields[K]
    ) => {
        setFields((prev) => ({ ...prev, [fieldName]: value }));
    };

    const handleChangeFieldError = <K extends keyof IApplicationFormFields>(
        fieldName: K,
        text?: string | null
    ) => {
        setFieldErrors((prev) => ({ ...prev, [fieldName]: text ?? null }));
    };

    const handleNameChange = (e: ChangeEvent<HTMLInputElement> | string) => {
        const value = isString(e) ? e : e.target.value;

        handleChangeFieldValue("name", value);
    };

    const handleFileChange: ChangeEventHandler<HTMLInputElement> = (
        event: ChangeEvent<HTMLInputElement> | null
    ) => {
        handleChangeFieldError("photo", null);

        if (!event) {
            handleChangeFieldValue("photo", null);
            return;
        }

        const selectedFile = event.target.files?.[0];

        handleChangeFieldValue("photo", selectedFile ?? null);
    };

    const handleCitizenshipChange = (index: number, value: string | number) => {
        const values = [...fields.citizenShip];
        values[index] = String(value);

        handleChangeFieldValue("citizenShip", values);
    };

    const handleSubmit = async () => {
        const { citizenShip, name, photo } = fields;

        if (!photo) {
            return;
        }

        const countriesId = new Set<number>();
        citizenShip.forEach((id) => id && countriesId.add(+id));

        setIsLoading(true);

        try {
            const candidate = await CandidatesAPI.createMyCandidate({
                name,
                photo,
                countriesId: [...countriesId],
            });

            dispatch(nomiActions.addCandidateToBeginning(candidate.data));

            navigate(routes.nomi);
        } catch (e) {
            console.error(e);
        } finally {
            Notifications.createSuccess("Your application was successful");
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!countries?.length) {
            dispatch(fetchCountries());
        }
    }, [countries?.length, dispatch]);

    const handleSelectOpen = useCallback(() => setIsAnySelectOpen(true), []);
    const handleSelectClose = useCallback(() => setIsAnySelectOpen(false), []);

    const handleRedirectTerms = (path: TAppRoute) => {
        dispatch(nomiActions.setApplicationCandidateFormFields(fields));

        navigate(path);
    };

    const isFormError =
        !fields.name ||
        !fields.photo ||
        !fields.citizenShip.some((value) => !!value);

    return (
        <MainLayout
            withGoBack
            additionalScrollHeight={10}
            hasNavbar={!isAnySelectOpen}
            subtitle="for Public Nominee"
            title="Application form"
        >
            <div className={styles.wrapper}>
                <Input
                    disabled={isLoading}
                    error={fieldErrors.name}
                    isError={!!fieldErrors.name}
                    maxLength={14}
                    placeholder="Candidates name"
                    title="Name*"
                    value={fields.name}
                    onChange={handleNameChange}
                />

                {isCountriesLoading ? (
                    <SunSpinner horizontalCentered />
                ) : (
                    <Select
                        disabled={isLoading}
                        options={
                            countries?.map(({ id, name, photo }) => ({
                                label: name,
                                value: id,
                                icon: (
                                    <img
                                        alt="flag"
                                        className={styles.flag}
                                        height={10}
                                        src={photo}
                                        width={14}
                                    />
                                ),
                            })) ?? []
                        }
                        placeholder="Choose country"
                        selectsAmount={3}
                        subtitle="country represented by the candidate, up to 3"
                        title="Citizenship*"
                        value={fields.citizenShip}
                        onChange={handleCitizenshipChange}
                        onSelectClose={handleSelectClose}
                        onSelectOpen={handleSelectOpen}
                    />
                )}

                <InputFile
                    allowTypes={allowTypes}
                    disabled={isLoading}
                    error={fieldErrors.photo}
                    isError={!!fieldErrors.photo}
                    maxFileSizeMB={maxFileSizeMB}
                    subtitle="The candidate photo will be styled according to Tap the System before being added to the Public Nominee list)"
                    title="Candidate Photo**"
                    value={fields.photo}
                    onChange={handleFileChange}
                />

                <div
                    className={clsx(styles.bottom, {
                        [styles["bottom_down-ios"]]: isIOS && isAnySelectOpen,
                        [styles["bottom_down-other"]]:
                            !isIOS && isAnySelectOpen,
                    })}
                >
                    <Button
                        className={styles.btn}
                        disabled={isFormError || isLoading}
                        status={isLoading ? "loading" : "none"}
                        onClick={handleSubmit}
                    >
                        Submit candidate
                    </Button>

                    <Typography variant="text3Regular">
                        *By submitting, you agree to the{" "}
                        <span
                            style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                handleRedirectTerms(routes.additionTerms)
                            }
                        >
                            Candidate Addition Terms
                        </span>
                        ,{" "}
                        <span
                            style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                handleRedirectTerms(routes.privacyPolicy)
                            }
                        >
                            Privacy Policy
                        </span>
                        ,{" "}
                        <span
                            style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                handleRedirectTerms(routes.termsCondition)
                            }
                        >
                            Terms & Conditions
                        </span>
                    </Typography>
                </div>
            </div>
        </MainLayout>
    );
};

export default ApplicationFormScreen;
