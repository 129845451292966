import { FC, useEffect, useState } from "react";

import { intervalToDuration, isAfter } from "date-fns";

import { useAppDispatch } from "@hooks/useAppDispatch";

import TransparentBlock from "@atoms/TransparentBlock/TransparentBlock";

import { fetchUserInfo } from "@redux/user/user.thunk";

import { Notifications } from "@utils/notifications";

import { CircleCountdown } from "./CircleCountdown/CircleCountdown";
import CircleTap from "./CircleTap/CircleTap";
import styles from "./PointsInfo.module.scss";
import { IPointsInfo } from "./PointsInfo.types";

export const PointsInfo: FC<IPointsInfo> = ({
    currentValue,
    totalValue,
    currentEnergy,
    totalEnergy,
    regeneration,
    unrealizedTaps,
}) => {
    const [isHaveEnergy, setIsHaveEnergy] = useState(true);
    const [timestamp, setTimestamp] = useState(0);
    const [details, setDetails] = useState({
        hours: -1,
        minutes: -1,
        seconds: -1,
    });

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (totalValue === currentValue) {
            setIsHaveEnergy(false);

            const now = new Date();
            const nextDay = new Date(
                Date.UTC(
                    now.getUTCFullYear(),
                    now.getUTCMonth(),
                    now.getUTCDate() + 1,
                    0,
                    0,
                    0
                )
            );
            const timestamp = nextDay.getTime();

            setTimestamp(timestamp);
        } else {
            setIsHaveEnergy(true);
        }
    }, [currentValue, dispatch, totalValue]);

    useEffect(() => {
        const now = new Date();
        const targetDate = new Date(timestamp);

        if (isAfter(now, targetDate)) {
            return;
        }

        const duration = intervalToDuration({
            start: now,
            end: targetDate,
        });

        const { days = 0, hours = 0, minutes = 0, seconds = 0 } = duration;

        const allHours = days * 24 + hours;

        setDetails({ hours: allHours, minutes, seconds });
    }, [timestamp]);

    return (
        <TransparentBlock rootClassName={styles.wrapper}>
            {isHaveEnergy ? (
                <CircleTap
                    currentEnergy={currentEnergy}
                    currentValue={currentValue}
                    regeneration={regeneration}
                    totalEnergy={totalEnergy}
                    totalValue={totalValue}
                    onFinish={() => {
                        setIsHaveEnergy(false);

                        const now = new Date();
                        const nextDay = new Date(
                            Date.UTC(
                                now.getUTCFullYear(),
                                now.getUTCMonth(),
                                now.getUTCDate() + 1,
                                0,
                                0,
                                0
                            )
                        );
                        const timestamp = nextDay.getTime();

                        setTimestamp(timestamp);

                        Notifications.createSuccess(
                            "Congrats! You`ve earned 1 extra Free Vote"
                        );

                        dispatch(fetchUserInfo());
                    }}
                />
            ) : (
                details.hours !== -1 && (
                    <CircleCountdown
                        hours={details.hours}
                        minutes={details.minutes}
                        seconds={details.seconds}
                        totalTaps={totalValue}
                        unrealizedTaps={unrealizedTaps}
                    />
                )
            )}
        </TransparentBlock>
    );
};
