import { memo, useEffect } from "react";

import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";

import IconBlock from "@atoms/IconBlock";
import SunSpinner from "@atoms/SunSpinner";
import Typography from "@atoms/Typography";

import MainLayout from "@organisms/layouts/MainLayout";

import { TicketSVG } from "@svg/TicketSVG";

import { constantsSelector } from "@redux/constants/constants.selectors";
import { fetchConstants } from "@redux/constants/constants.thunk";
import { userInfoSelector } from "@redux/user/user.selectors";
import { fetchUserInfo } from "@redux/user/user.thunk";
import {
    myCandidatesSelector,
    publicCandidatesSelector,
    tapsInfoSelector,
    voteIsLoadingsSelector,
} from "@redux/vote/vote.selectors";
import { fetchCandidatesList, fetchTapsInfo } from "@redux/vote/vote.thunk";

import star from "../../../assets/images/Star.png";
import styles from "./VoteScreen.module.scss";
import { CountDown } from "./components/CountDown/CountDown";
import { PointsInfo } from "./components/PointsInfo/PointsInfo";
import { SpinInfo } from "./components/SpinInfo/SpinInfo";
import { VoteNominees } from "./components/VoteNominees/VoteNominees";

const VoteScreen = () => {
    const dispatch = useAppDispatch();

    const { isUserInfoLoading, userInfo } = useAppSelector(userInfoSelector);

    const { isCandidatesLoading, isTapsLoading } = useAppSelector(
        voteIsLoadingsSelector
    );

    const myCandidatesCard = useAppSelector(myCandidatesSelector);
    const publicCandidatesCard = useAppSelector(publicCandidatesSelector);
    const { game, user } = useAppSelector(tapsInfoSelector) ?? {};
    const constants = useAppSelector(constantsSelector);

    useEffect(() => {
        dispatch(fetchUserInfo());
        dispatch(fetchCandidatesList());
        dispatch(fetchTapsInfo());
        dispatch(fetchConstants("prize_pool"));
    }, [dispatch]);

    const {
        pre_lucky_spin = 0,
        votes_free = 0,
        lucky_spin = 0,
    } = userInfo ?? {};

    return (
        <MainLayout
            additionalScrollHeight={10}
            subtitle="Tap to vote or Vote with Stars!"
            title="Choose global ruler!"
        >
            <div className={styles.wrapper}>
                <div className={styles.top}>
                    {isUserInfoLoading && !userInfo ? (
                        <SunSpinner horizontalCentered />
                    ) : (
                        <>
                            <SpinInfo
                                className={styles.info}
                                rows={[
                                    {
                                        title: "PreLuckySpin Ticket",
                                        value: pre_lucky_spin,
                                        iconBlock: (
                                            <IconBlock
                                                borderRadius="small"
                                                mode="gray"
                                                size="small"
                                            >
                                                <TicketSVG />
                                            </IconBlock>
                                        ),
                                    },
                                    {
                                        title: "Free Votes",
                                        value: votes_free,
                                        emptyIconBlock: true,
                                    },
                                ]}
                            />

                            <SpinInfo
                                className={styles.info}
                                rows={[
                                    {
                                        title: "LuckySpin ticket",
                                        value: lucky_spin,
                                        iconBlock: (
                                            <IconBlock
                                                borderRadius="small"
                                                mode="red"
                                                size="small"
                                            >
                                                <TicketSVG />
                                            </IconBlock>
                                        ),
                                    },
                                    "1 Vote = 1 LuckySpin ticket",
                                ]}
                            />
                        </>
                    )}

                    <div className={styles.luckySpinBlock}>
                        <Typography variant="text2Regular">
                            LuckySpin fund
                            <br />
                            <Typography variant="textSmall">
                                (updated every 24 hours)
                            </Typography>
                        </Typography>
                        <div className={styles.starContainer}>
                            <img
                                alt="star"
                                className={styles.star}
                                src={star}
                            />
                            <Typography variant="text2Bold">
                                {constants.data[0].value}
                            </Typography>
                            <img
                                alt="star"
                                className={styles.star}
                                src={star}
                            />
                        </div>
                    </div>
                    {!isTapsLoading ? (
                        <PointsInfo
                            currentEnergy={user?.energy}
                            currentValue={user?.taps}
                            regeneration={game?.energy_regeneration}
                            totalEnergy={game?.energy_max}
                            totalValue={game?.daily_taps}
                            unrealizedTaps={userInfo?.unrealized_taps}
                        />
                    ) : (
                        <SunSpinner horizontalCentered />
                    )}
                </div>

                <CountDown />

                {isCandidatesLoading ? (
                    <SunSpinner horizontalCentered />
                ) : (
                    <>
                        {publicCandidatesCard && (
                            <VoteNominees
                                withPositions
                                cards={publicCandidatesCard} // voteCardsMock
                                title="Nominees"
                            />
                        )}

                        {myCandidatesCard && (
                            <VoteNominees
                                cards={myCandidatesCard} // voteCardsMock
                                title="Public nominees"
                            />
                        )}
                    </>
                )}
            </div>
        </MainLayout>
    );
};

export default memo(VoteScreen);
