import TransparentBlock from "@atoms/TransparentBlock/TransparentBlock";
import Typography from "@atoms/Typography";

import styles from "../govern-screen.module.scss";

const NomiTaskBlock = () => {
    return (
        <>
            <Typography variant="textBold">Nomi</Typography>
            <TransparentBlock>
                <Typography
                    style={{ lineHeight: "20px" }}
                    variant="text2Regular"
                >
                    You can add your own candidate even yourself for Telegram
                    Stars.
                </Typography>
            </TransparentBlock>

            <Typography variant="textBold">Tasks</Typography>
            <TransparentBlock>
                <Typography
                    style={{ lineHeight: "20px" }}
                    variant="text2Regular"
                >
                    You can get more free taps and free votes.
                </Typography>
            </TransparentBlock>
            <div className={styles.blueBlock}>
                <Typography
                    style={{ lineHeight: "20px" }}
                    variant="text2Regular"
                >
                    Join the global vote in the Tap the system and help choose
                    the world`s president with stars rewards in the LuckySpin!
                </Typography>
            </div>
        </>
    );
};

export default NomiTaskBlock;
