import { FC } from "react";

import { ISvg } from "./SVG.types";

export const ThirdPositionSVG: FC<ISvg> = (props) => {
    return (
        <svg
            fill="none"
            height="16"
            viewBox="0 0 16 16"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M16 8.00005C16 3.58176 12.4182 0 8 0C3.58169 0 0 3.58176 0 8.00005C0 12.4182 3.58169 16 8 16C12.4182 16 16 12.4182 16 8.00005Z"
                fill="#E47323"
            />
            <path
                d="M8.00526 0.666016C3.96139 0.666016 0.671875 3.95551 0.671875 7.99935C0.671875 12.0432 3.96139 15.3327 8.00526 15.3327C12.049 15.3327 15.3385 12.0432 15.3385 7.99935C15.3385 3.95551 12.049 0.666016 8.00526 0.666016Z"
                fill="#B4571D"
            />
            <path
                d="M14.6615 8.00088C14.6615 4.32478 11.6711 1.33398 7.99507 1.33398C6.95247 1.33398 5.96515 1.57425 5.08574 2.00278C4.16812 2.44965 3.36788 3.10057 2.74433 3.89679C2.45974 4.26011 2.21182 4.6537 2.00631 5.07229C1.57249 5.95654 1.32812 6.95089 1.32812 8.00088C1.32812 8.77968 1.46249 9.52711 1.70877 10.2224C2.45071 12.3165 4.21135 13.9323 6.39159 14.4723C6.90513 14.5996 7.44257 14.6673 7.99507 14.6673C9.22465 14.6673 10.3772 14.3329 11.367 13.7502C11.8084 13.4899 12.2175 13.1809 12.5868 12.8295C13.2829 12.1672 13.8363 11.3565 14.1956 10.4483C14.4962 9.69038 14.6615 8.86479 14.6615 8.00088Z"
                fill="#E47323"
            />
            <path
                d="M11.3281 13.7102L2.00368 4.66602C1.57155 5.58759 1.32812 6.6239 1.32812 7.71821C1.32812 8.52988 1.46196 9.30885 1.70729 10.0334L6.37197 14.4628C6.88352 14.5955 7.41888 14.666 7.96923 14.666C9.19405 14.666 10.3422 14.3175 11.3281 13.7102Z"
                fill="#F68943"
            />
            <path
                d="M14.0052 10.3207L4.98917 2C4.08099 2.44026 3.289 3.08156 2.67188 3.86601L12.4129 12.6667C13.1019 12.0141 13.6496 11.2155 14.0052 10.3207Z"
                fill="#F68943"
            />
            <path
                d="M7.38486 7.07064C7.84205 7.07064 8.17982 6.97511 8.39817 6.78404C8.62335 6.58616 8.73594 6.34051 8.73594 6.04709V5.97544C8.73594 5.64791 8.62676 5.39885 8.40841 5.22825C8.19687 5.05084 7.91028 4.96213 7.54863 4.96213C7.20062 4.96213 6.9038 5.0406 6.65814 5.19755C6.41932 5.35449 6.21802 5.56944 6.05425 5.84238L5.27636 5.17708C5.38554 5.02013 5.51177 4.87001 5.65507 4.72672C5.79837 4.58342 5.96213 4.4606 6.14637 4.35824C6.33061 4.24906 6.54214 4.16377 6.78097 4.10235C7.0198 4.03412 7.28933 4 7.58957 4C7.92393 4 8.2344 4.04094 8.521 4.12283C8.80759 4.20471 9.05665 4.32753 9.26819 4.4913C9.47972 4.64825 9.64349 4.84272 9.75949 5.07472C9.87549 5.2999 9.93349 5.55579 9.93349 5.84238C9.93349 6.06756 9.89596 6.27227 9.8209 6.45651C9.75266 6.64075 9.65372 6.80452 9.52407 6.94781C9.40125 7.08428 9.25454 7.19687 9.08395 7.28558C8.91336 7.37429 8.73253 7.43911 8.54147 7.48006V7.53123C8.74618 7.57217 8.94065 7.64041 9.12489 7.73594C9.30913 7.82465 9.47289 7.94406 9.61619 8.09418C9.75949 8.23748 9.87208 8.41148 9.95396 8.61619C10.0358 8.81408 10.0768 9.04267 10.0768 9.30197C10.0768 9.61585 10.0154 9.90245 9.89255 10.1617C9.76972 10.4142 9.59231 10.6326 9.3603 10.8168C9.13512 11.0011 8.86218 11.1443 8.54147 11.2467C8.22076 11.3422 7.86252 11.39 7.46675 11.39C7.13239 11.39 6.83556 11.3525 6.57626 11.2774C6.32379 11.2092 6.09861 11.1171 5.90072 11.0011C5.70283 10.8782 5.52883 10.7418 5.37871 10.5916C5.23542 10.4347 5.10918 10.2743 5 10.1106L5.84954 9.4555C6.02696 9.74209 6.23508 9.97751 6.47391 10.1617C6.71956 10.3392 7.05392 10.4279 7.47698 10.4279C7.92734 10.4279 8.27193 10.3255 8.51076 10.1208C8.74959 9.90927 8.869 9.61927 8.869 9.25079V9.16891C8.869 8.80043 8.74277 8.52066 8.49029 8.3296C8.24464 8.13171 7.8864 8.03277 7.41557 8.03277H6.64791V7.07064H7.38486Z"
                fill="white"
            />
        </svg>
    );
};
