import toast from "react-hot-toast";

import { CloseSvg } from "@svg/CloseSVG";
import { SuccessSVG } from "@svg/SuccessSVG";

export enum ENotifications {
    ERROR = "ERROR",
    SUCCESS = "SUCCESS",
}

class NotificationsClass {
    public createSuccess(text: string) {
        toast.success(text, {
            icon: <SuccessSVG />,
        });
    }

    public createError(text: string) {
        toast.error(text, {
            icon: <CloseSvg />,
        });
    }
}

export const Notifications = new NotificationsClass();
