import {
    IApplicationFormFields,
    TApplicationFormFieldsErrors,
} from "./ApplicationFormScreen.types";

export const allowTypes = ["image/jpeg", "image/png", "image/gif"]; // Разрешенные типы файлов
export const maxFileSizeMB = 10;

export const applicationFormDefaultValues: IApplicationFormFields = {
    citizenShip: [],
    name: "",
    photo: null,
} as const;

export const applicationFormDefaultErrors: TApplicationFormFieldsErrors = {
    citizenShip: null,
    name: null,
    photo: null,
} as const;
