import { memo } from "react";

import SunSpinner from "@atoms/SunSpinner";

import MainLayout from "@organisms/layouts/MainLayout";

import styles from "./LoadingScreen.module.scss";

const LoadingScreen = () => {
    return (
        <MainLayout hasNavbar={false} title={undefined}>
            <div className={styles.wrapper}>
                <SunSpinner horizontalCentered />
            </div>
        </MainLayout>
    );
};

export default memo(LoadingScreen);
