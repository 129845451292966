import { useEffect } from "react";

import { request } from "@services/Request";

import { tokenStorageKey } from "@constants/localStorage";

import { isAuthSelector } from "@redux/user/user.selectors";
import { userActions } from "@redux/user/user.slice";
import { fetchUserToken } from "@redux/user/user.thunk";

import { useAppDispatch } from "./useAppDispatch";
import { useAppSelector } from "./useAppSelector";

export const useAuthUser = () => {
    const dispatch = useAppDispatch();

    const isAuth = useAppSelector(isAuthSelector);

    useEffect(() => {
        if (isAuth) {
            return;
        }

        // const token = localStorage.getItem(tokenStorageKey);

        // if (token) {
        //     request.setToken(token);
        //     dispatch(userActions.setAuth(true));

        //     return;
        // }

        const initData =
            window.Telegram?.WebApp?.initData ||
            import.meta.env.VITE_APP_TEST_TOKEN;

        dispatch(fetchUserToken({ init_data: initData }));
    }, [dispatch, isAuth]);
};
