import React, { useEffect, useState } from "react";

import ReactDOM from "react-dom";

import Typography from "@atoms/Typography";

import { FloatingTextProps } from "./FloatingText.types";
import styles from "./floating-text.module.scss";

const FloatingText: React.FC<FloatingTextProps> = ({ x, y }) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => setVisible(false), 1000);
        return () => clearTimeout(timeout);
    }, []);

    if (!visible) return null;

    return ReactDOM.createPortal(
        <div
            className={styles.container}
            style={{
                top: y,
                left: x,
            }}
        >
            <Typography variant="textBold">+1</Typography>
        </div>,
        document.body
    );
};

export default FloatingText;
