import { FC, useCallback, useState } from "react";

import TransparentBlock from "@atoms/TransparentBlock/TransparentBlock";
import Typography from "@atoms/Typography";

import Modal from "@molecules/Modal";

import { ICandidatesBlock } from "./CandidateBlock.types";
import CandidateCard from "./CandidateCard/CandidateCard";
import styles from "./candidates-block.module.scss";

const CandidatesBlock: FC<ICandidatesBlock> = ({
    candidates,
    onPay,
    isPayLoading,
}) => {
    const [deletingReason, setDeletingReason] = useState<string | null>(null);

    const handleShowReason = useCallback((reason: string) => {
        setDeletingReason(reason);
    }, []);

    if (candidates.length === 0) {
        return (
            <TransparentBlock rootClassName={styles.appsBlock}>
                <Typography variant="textBold">Your applications</Typography>
                <Typography className={styles.downText} variant="text2Regular">
                    You don`t have any submited candidates yet
                </Typography>
            </TransparentBlock>
        );
    }

    return (
        <div className={styles.cardContainer}>
            <Modal
                open={!!deletingReason}
                title="Reason for Deletion from Candidate List"
                windowClassName={styles.reasonModal}
                onClose={() => setDeletingReason(null)}
            >
                <Typography className={styles.reason} variant="text2Regular">
                    {deletingReason}
                </Typography>
            </Modal>

            {candidates.map((el, index) => {
                return (
                    <CandidateCard
                        key={index}
                        candidate={el}
                        id={index + 1}
                        isPayLoading={isPayLoading}
                        onPay={onPay}
                        onShowReason={handleShowReason}
                    />
                );
            })}
        </div>
    );
};

export default CandidatesBlock;
