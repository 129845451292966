import { FC } from "react";

import { ISvg } from "./SVG.types";

export const DoubleSuccessSVG: FC<ISvg> = (props) => {
    return (
        <svg
            fill="none"
            height="20"
            viewBox="0 0 20 20"
            width="20"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M11.5781 6.58435L4.57813 13.4594C4.49051 13.5452 4.3727 13.5934 4.25001 13.5934C4.12731 13.5934 4.00951 13.5452 3.92188 13.4594L0.921883 10.5133C0.877921 10.4702 0.842877 10.4188 0.818751 10.3622C0.794625 10.3056 0.78189 10.2447 0.781274 10.1832C0.780657 10.1216 0.79217 10.0606 0.815157 10.0035C0.838143 9.94635 0.872152 9.89433 0.915242 9.85037C0.958332 9.80641 1.00966 9.77136 1.06629 9.74724C1.12293 9.72311 1.18376 9.71038 1.24531 9.70976C1.30687 9.70914 1.36794 9.72066 1.42505 9.74364C1.48215 9.76663 1.53417 9.80064 1.57813 9.84373L4.25001 12.4672L10.9219 5.91482C10.9653 5.86927 11.0173 5.83291 11.0751 5.80791C11.1328 5.78291 11.1949 5.76978 11.2578 5.7693C11.3207 5.76882 11.3831 5.78101 11.4412 5.80514C11.4993 5.82926 11.5519 5.86483 11.596 5.90972C11.64 5.95462 11.6746 6.00792 11.6976 6.06645C11.7206 6.12498 11.7316 6.18755 11.73 6.25043C11.7283 6.3133 11.714 6.3752 11.6879 6.43244C11.6618 6.48967 11.6245 6.54107 11.5781 6.58357V6.58435ZM19.0844 5.92185C18.9973 5.83324 18.8786 5.78281 18.7544 5.78163C18.6302 5.78046 18.5106 5.82865 18.4219 5.9156L11.75 12.4679L10.1695 10.9156C10.1256 10.8725 10.0736 10.8384 10.0166 10.8153C9.95949 10.7923 9.89843 10.7807 9.83688 10.7812C9.77532 10.7818 9.71448 10.7945 9.65782 10.8185C9.60116 10.8426 9.54979 10.8776 9.50665 10.9215C9.46351 10.9654 9.42944 11.0174 9.40638 11.0744C9.38333 11.1315 9.37175 11.1926 9.37229 11.2541C9.37283 11.3157 9.3855 11.3765 9.40955 11.4332C9.43361 11.4898 9.4686 11.5412 9.51251 11.5844L11.4219 13.4594C11.5095 13.5452 11.6273 13.5934 11.75 13.5934C11.8727 13.5934 11.9905 13.5452 12.0781 13.4594L19.0781 6.58435C19.1667 6.49729 19.2172 6.37862 19.2184 6.2544C19.2195 6.13018 19.1713 6.01057 19.0844 5.92185Z"
                fill="white"
            />
        </svg>
    );
};
