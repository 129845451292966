import { TRootState } from "../store";

export const myCandidatesSelector = (state: TRootState) =>
    state.vote.myCandidates;
export const publicCandidatesSelector = (state: TRootState) =>
    state.vote.publicCandidates;
export const tapsInfoSelector = (state: TRootState) => state.vote.taps;
export const voteIsLoadingsSelector = (state: TRootState) => ({
    isCandidatesLoading: state.vote.isCandidateLoading,
    isTapsLoading: state.vote.isTapsLoading,
});
