import TransparentBlock from "@atoms/TransparentBlock/TransparentBlock";
import Typography from "@atoms/Typography";

import styles from "../govern-screen.module.scss";

const FirstBlock = () => {
    return (
        <>
            <div className={styles.blueBlock}>
                <Typography variant="text2Regular">
                    Tap the system is where the whole world picks the global
                    ruler from the list of politicians, influencers and Public
                    Nominees from around the world!{" "}
                    <Typography element="span" variant="text2Bold">
                        Results of the voting will be revealed on November 6,
                        2024.
                    </Typography>
                </Typography>
            </div>

            <div className={styles.dateVotingBlok}>
                <TransparentBlock rootClassName={styles.radiusBlock}>
                    <Typography variant="text2Bold">Early Voting:</Typography>
                    <Typography
                        className={styles.marginText}
                        variant="text2Regular"
                    >
                        01.11.2024 - 05.11.2024
                    </Typography>
                </TransparentBlock>
                <TransparentBlock rootClassName={styles.radiusBlock}>
                    <Typography variant="text2Bold">General Voting:</Typography>
                    <Typography
                        className={styles.marginText}
                        variant="text2Regular"
                    >
                        05.11.2024
                    </Typography>
                </TransparentBlock>
            </div>
        </>
    );
};

export default FirstBlock;
