import { configureStore } from "@reduxjs/toolkit";

import appReducer from "@redux/app/app.slice";
import constantsReducer from "@redux/constants/constants.slice";
import { referralsReducer } from "@redux/referrals/referrals.slice";
import { tasksReducer } from "@redux/tasks/task.slice";

import { commonReducer } from "./common/common.slice";
import { nomiReducer } from "./nomi/nomi.slice";
import { userReducer } from "./user/user.slice";
import { voteReducer } from "./vote/vote.slice";

export const store = configureStore({
    reducer: {
        app: appReducer,
        vote: voteReducer,
        user: userReducer,
        constants: constantsReducer,
        common: commonReducer,
        nomi: nomiReducer,
        tasks: tasksReducer,
        referrals: referralsReducer,
    },
});

export type TRootState = ReturnType<typeof store.getState>;
export type TAppDispatch = typeof store.dispatch;
