import { FC } from "react";

import { useNavigate } from "react-router-dom";

import { routes } from "@constants/routes";

import Button from "@atoms/Button";
import Typography from "@atoms/Typography";

import MainLayout from "@organisms/layouts/MainLayout";

import leftLenta from "../../../assets/images/LeftLenta.png";
import rightLenta from "../../../assets/images/RightLenta.png";
import stars from "../../../assets/images/Stars.png";
import styles from "./start-screen.module.scss";

const StartScreen: FC = () => {
    const navigate = useNavigate();

    return (
        <MainLayout
            additionalScrollHeight={10}
            bgName="StartBg"
            hasNavbar={false}
            title=""
        >
            <img alt="left" className={styles.leftLenta} src={leftLenta} />
            <img alt="right" className={styles.rightLenta} src={rightLenta} />
            <div className={styles.wrapper}>
                <img alt="stars" src={stars} />
                <Typography variant="display">TAP THE SYSTEM</Typography>
                <div className={styles.descText}>
                    <Typography variant="textRegular">
                        {" "}
                        Choose your global ruler{" "}
                    </Typography>
                    <Typography variant="textRegular">&</Typography>
                    <Typography variant="textRegular">
                        Win Telegram stars
                    </Typography>
                </div>

                <Button
                    className={styles.playBtn}
                    onClick={() => navigate(routes.rules)}
                >
                    Play the game
                </Button>
            </div>
        </MainLayout>
    );
};

export default StartScreen;
