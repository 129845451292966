import { FC } from "react";

import { ISvg } from "./SVG.types";

export const FirstPositionSVG: FC<ISvg> = (props) => {
    return (
        <svg
            fill="none"
            height="16"
            viewBox="0 0 16 16"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M16 8.00005C16 3.58176 12.4183 0 8 0C3.58174 0 0 3.58176 0 8.00005C0 12.4182 3.58174 16 8 16C12.4183 16 16 12.4182 16 8.00005Z"
                fill="#F7B019"
            />
            <path
                d="M8.00519 0.666138C3.96139 0.666138 0.671875 3.95564 0.671875 7.99947C0.671875 12.0433 3.96139 15.3328 8.00519 15.3328C12.049 15.3328 15.3385 12.0433 15.3385 7.99947C15.3385 3.95564 12.049 0.666138 8.00519 0.666138Z"
                fill="#E49D1B"
            />
            <path
                d="M14.6615 8.00076C14.6615 4.32466 11.6711 1.33386 7.99504 1.33386C6.95254 1.33386 5.96517 1.57413 5.0858 2.00266C4.16807 2.44953 3.36791 3.10044 2.74436 3.89667C2.45978 4.25998 2.21185 4.65358 2.00637 5.07217C1.57246 5.95642 1.32812 6.95077 1.32812 8.00076C1.32812 8.77956 1.46242 9.52699 1.70874 10.2222C2.45076 12.3164 4.21136 13.9322 6.39157 14.4722C6.9052 14.5995 7.44264 14.6672 7.99504 14.6672C9.22472 14.6672 10.3772 14.3328 11.3671 13.7501C11.8085 13.4898 12.2175 13.1807 12.5868 12.8294C13.283 12.1671 13.8363 11.3564 14.1957 10.4482C14.4963 9.69026 14.6615 8.86466 14.6615 8.00076Z"
                fill="#F7B019"
            />
            <path
                d="M11.3281 13.7103L2.00374 4.66614C1.57151 5.58771 1.32812 6.62402 1.32812 7.71833C1.32812 8.53 1.4619 9.30898 1.70726 10.0336L6.37191 14.4629C6.88354 14.5956 7.4189 14.6661 7.96915 14.6661C9.19406 14.6661 10.3421 14.3176 11.3281 13.7103Z"
                fill="#FBC035"
            />
            <path
                d="M14.6719 10.3207L5.12549 2C4.1638 2.44026 3.3253 3.08156 2.67188 3.86601L12.9858 12.6667C13.7154 12.0141 14.2953 11.2155 14.6719 10.3207Z"
                fill="#FBC035"
            />
            <path
                d="M5.43554 11.07V10.128H7.42082V4.83057H7.33979L5.6989 6.58288L5 5.95489L6.80295 4H8.55526V10.128H10.267V11.07H5.43554Z"
                fill="white"
            />
        </svg>
    );
};
