import { memo, useEffect, useState } from "react";

import { clsx } from "clsx";
import { useNavigate } from "react-router-dom";

import { routes } from "@constants/routes";

import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";

import Button from "@atoms/Button";
import IconBlock from "@atoms/IconBlock";
import SunSpinner from "@atoms/SunSpinner";
import Typography from "@atoms/Typography";

import InfoBlock from "@molecules/InfoBlock/InfoBlock";
import Modal from "@molecules/Modal";

import MainLayout from "@organisms/layouts/MainLayout";

import { ArrowRightSVG } from "@svg/ArrowRigthSVG";
import { CheckSVG } from "@svg/CheckSVG";
import { CrownSVG } from "@svg/CrownSVG";
import { DoubleSuccessSVG } from "@svg/DoubleSuccessSVG";
import { TelegramSVG } from "@svg/TelegramSVG";

import { referralsSelector } from "@redux/referrals/referrals.selector";
import {
    fetchReferralsInfo,
    fetchReferralsList,
} from "@redux/referrals/referrals.thunk";
import { tasksInfoSelector } from "@redux/tasks/task.selectors";
import { ITask } from "@redux/tasks/task.slice";
import {
    fetchTasks,
    sendCheckTelegramFollow,
    sendClaimTask,
} from "@redux/tasks/task.thunk";

import { Notifications } from "@utils/notifications";

import { openTelegramLink, shareURL } from "@telegram-apps/sdk";

import styles from "./TasksScreen.module.scss";
import FrensList from "./components/FrensList";
import TotalBlock from "./components/TotalBlock";

const statusToBtnText: any = {
    claim: "Claim",
    claimed: <CheckSVG />,
    start: "Start",
    disabled: "Claim",
};

const typeToIcon: any = {
    friends: <DoubleSuccessSVG />,
    telegram: <TelegramSVG />,
};

const TasksScreen = () => {
    const [IsOpenInviteModal, setIsOpenInviteModal] = useState(false);
    const [IsOpenSubscribeModal, setIsOpenSubscribeModal] = useState(false);
    const [selectedSubscribe, setSelectedSubscribe] = useState<ITask | null>(
        null
    );

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { info, isInfoLoading, referralsList, isReferralsListLoading } =
        useAppSelector(referralsSelector);

    const { data, isTasksLoading } = useAppSelector(tasksInfoSelector);

    useEffect(() => {
        dispatch(fetchTasks());
        dispatch(fetchReferralsInfo());
        dispatch(fetchReferralsList());
    }, [dispatch]);

    const handleCopyLink = () => {
        navigator.clipboard.writeText(
            `${import.meta.env.VITE_APP_TELEGRAM_LINK}?startapp=ref-${
                info.referral_code
            }`
        );
        setIsOpenInviteModal(false);
        Notifications.createSuccess("Refferal link is copied");
    };

    const handleSubscribeOnChannel = () => {
        if (!selectedSubscribe) {
            return;
        }

        let timeout: any;

        const checkFollowing = (showNotify: boolean) => {
            dispatch(sendCheckTelegramFollow(selectedSubscribe.id)).then(
                ({ payload }) => {
                    if (payload?.success) {
                        clearTimeout(timeout);
                        dispatch(fetchTasks());
                        setIsOpenSubscribeModal(false);
                        setSelectedSubscribe(null);
                    } else if (showNotify) {
                        Notifications.createError("Please follow on channel");
                    }
                }
            );
        };

        timeout = setTimeout(() => {
            checkFollowing(true);
        }, 5_000);

        checkFollowing(false);
        openTelegramLink(selectedSubscribe.url);
    };

    const handleClaimTask = (
        task: ITask,
        refreshReferralsInfo = false,
        showNotify = false
    ) => {
        dispatch(sendClaimTask(task.id)).then(({ payload }) => {
            if (payload?.success) {
                dispatch(fetchTasks());

                if (refreshReferralsInfo) {
                    dispatch(fetchReferralsInfo());
                }

                if (showNotify) {
                    Notifications.createSuccess("Congrats! Free Vote updated");
                }
            } else {
                Notifications.createError("Something went wrong");
            }
        });
    };

    const handleShareLink = () => {
        shareURL(
            `${import.meta.env.VITE_APP_TELEGRAM_LINK}?startapp=ref-${
                info.referral_code
            }`,
            "Join me on TapTheSystem and let's choose the global leader together and get stars!🙌"
        );
    };

    return (
        <MainLayout additionalScrollHeight={10} title="Tasks">
            <Modal
                open={IsOpenInviteModal}
                title="Invite a fren"
                onClose={() => setIsOpenInviteModal(false)}
            >
                <div className={styles.frenModalBtns}>
                    <Button variant="secondary" onClick={handleShareLink}>
                        Send
                    </Button>
                    <Button variant="secondary" onClick={handleCopyLink}>
                        Copy link
                    </Button>
                </div>
            </Modal>
            <Modal
                open={IsOpenSubscribeModal}
                title="Subscribe to Tap the System Telegram Channel"
                onClose={() => setIsOpenSubscribeModal(false)}
            >
                <div className={styles.frenModalBtns}>
                    <Typography variant="textBold">
                        {selectedSubscribe?.title}
                    </Typography>
                    <Button
                        variant="secondary"
                        onClick={handleSubscribeOnChannel}
                    >
                        Subscribe
                    </Button>
                </div>
            </Modal>

            <div className={styles.wrapper}>
                <Button
                    className={styles.leaders}
                    variant="halfTransparent"
                    onClick={() => navigate(routes.leaderboard)}
                >
                    <IconBlock borderRadius="big" mode="gray">
                        <CrownSVG />
                    </IconBlock>

                    <Typography variant="text2Bold">Top 100 leaders</Typography>

                    <ArrowRightSVG className={styles.arrow} />
                </Button>

                <div className={styles.infoBlock}>
                    <Typography variant="textBold">Specials</Typography>
                    {!isTasksLoading ? (
                        data?.map((el) => {
                            return (
                                <InfoBlock
                                    key={el.id}
                                    btnStatus={el.status}
                                    buttonText={statusToBtnText[el.status]}
                                    claimCount={el.claim_count}
                                    icon={typeToIcon[el.task_type]}
                                    textDesc={el.description}
                                    textTitle={el.title}
                                    onClick={() => {
                                        if (el.status === "claimed") return;

                                        if (el.task_type === "friends") {
                                            // TODO: Добавить Loading
                                            handleClaimTask(el, true, true);
                                        } else if (
                                            el.task_type === "telegram"
                                        ) {
                                            if (el.status === "start") {
                                                setSelectedSubscribe(el);
                                                setIsOpenSubscribeModal(true);
                                            } else if (el.status === "claim") {
                                                // TODO: Добавить Loading
                                                handleClaimTask(el);
                                            }
                                        }
                                    }}
                                />
                            );
                        })
                    ) : (
                        <SunSpinner />
                    )}
                </div>

                <div className={styles.bottom}>
                    <div className={clsx(styles.infoBlock, styles.inviteBlock)}>
                        <Typography variant="textBold">
                            Invite friends & earn Free Votes
                        </Typography>
                        <TotalBlock
                            isLoading={isInfoLoading}
                            totalFreeVotes={info.earn_votes}
                            totalFrens={info.total_frens}
                        />
                        <Typography variant="text3Regular">
                            10 friends = 1 Free Vote
                        </Typography>
                    </div>

                    <Button
                        className={styles.inviteFrenBtn}
                        variant="standard"
                        onClick={() => setIsOpenInviteModal(true)}
                    >
                        <Typography variant="textBold">
                            Invite a fren
                        </Typography>
                    </Button>

                    <FrensList
                        frensData={referralsList}
                        isLoading={isReferralsListLoading}
                        unclaimedTaps={info.unclaimed_taps}
                    />
                </div>
            </div>
        </MainLayout>
    );
};

export default memo(TasksScreen);
