import { createAsyncThunk } from "@reduxjs/toolkit";

import { ReferralsAPI } from "@services/endpoints/Referrals/Referrals";

export const fetchReferralsInfo = createAsyncThunk(
    "referrals/info",
    async () => {
        return (await ReferralsAPI.getReferralsInfo()).data;
    }
);

export const fetchReferralsList = createAsyncThunk(
    "referrals/list",
    async () => {
        return (await ReferralsAPI.getReferralsList()).data;
    }
);

export const fetchLeaderboard = createAsyncThunk(
    "referrals/leaderboard",
    async () => {
        return (await ReferralsAPI.getLeaderboard()).data;
    }
);

export const fetchClaimTaps = createAsyncThunk(
    "referrals/claimTaps",
    async () => {
        return (await ReferralsAPI.claimTaps()).data;
    }
);
