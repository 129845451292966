import { createSlice } from "@reduxjs/toolkit";

import {
    fetchClaimTaps,
    fetchLeaderboard,
    fetchReferralsInfo,
    fetchReferralsList,
} from "./referrals.thunk";

export interface IReferralInfo {
    referral_code: string;
    earn_taps: number;
    earn_votes: number;
    total_frens: number;
    unclaimed_taps: number;
}

export interface IReferral {
    id: number;
    username: string;
    earn_taps: number;
    earn_claimed: boolean;
}

export interface ILeaderboardUser {
    username: string;
    total_frens: number;
    position: number;
}

export interface ILeaderboard {
    user: ILeaderboardUser;
    leaders: ILeaderboardUser[];
}

export interface IReferralsState {
    info: IReferralInfo;

    referralsList: IReferral[];
    leaderboard: ILeaderboard;

    isInfoLoading: boolean;
    isReferralsListLoading: boolean;
    isLeaderboardLoading: boolean;
}

const initialState: IReferralsState = {
    info: {
        referral_code: "",
        earn_taps: 0,
        earn_votes: 0,
        total_frens: 0,
        unclaimed_taps: 0,
    },

    referralsList: [],
    leaderboard: {
        user: {
            username: "",
            total_frens: 0,
            position: 0,
        },
        leaders: [],
    },

    isInfoLoading: false,
    isReferralsListLoading: false,
    isLeaderboardLoading: false,
};

export const referralsSlice = createSlice({
    initialState,
    name: "referrals",
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchReferralsInfo.fulfilled, (state, { payload }) => {
                state.info = payload;
                state.isInfoLoading = false;
            })
            .addCase(fetchReferralsInfo.pending, (state) => {
                state.isInfoLoading = true;
            })
            .addCase(fetchReferralsInfo.rejected, (state) => {
                state.isInfoLoading = false;
            });
        builder
            .addCase(fetchReferralsList.fulfilled, (state, { payload }) => {
                state.referralsList = payload;
                state.isReferralsListLoading = false;
            })
            .addCase(fetchReferralsList.pending, (state) => {
                state.isReferralsListLoading = true;
            })
            .addCase(fetchReferralsList.rejected, (state) => {
                state.isReferralsListLoading = false;
            });
        builder
            .addCase(fetchLeaderboard.fulfilled, (state, { payload }) => {
                state.leaderboard = payload;

                state.isLeaderboardLoading = false;
            })
            .addCase(fetchLeaderboard.pending, (state) => {
                state.isLeaderboardLoading = true;
            })
            .addCase(fetchLeaderboard.rejected, (state) => {
                state.isLeaderboardLoading = false;
            });
        builder
            .addCase(fetchClaimTaps.fulfilled, (state, { payload }) => {
                state.info = payload;

                state.isInfoLoading = false;
            })
            .addCase(fetchClaimTaps.pending, (state) => {
                state.isInfoLoading = true;
            })
            .addCase(fetchClaimTaps.rejected, (state) => {
                state.isInfoLoading = false;
            });
    },
});

export const referralsActions = { ...referralsSlice.actions };

export const referralsReducer = referralsSlice.reducer;
