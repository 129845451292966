import { useEffect } from "react";

import { useAppDispatch } from "@hooks/useAppDispatch";

import MainLayout from "@organisms/layouts/MainLayout";

import { fetchConstants } from "@redux/constants/constants.thunk";

import DocumentsBlock from "./components/DocumentsBlock";
import FirstBlock from "./components/FirstBlock";
import LuckySpinBlock from "./components/LuckySpinBlock";
import NomiTaskBlock from "./components/NomiTaskBlock";
import TypesOfVoteBlock from "./components/TypesOfVoteBlock";
import styles from "./govern-screen.module.scss";

const GovernScreen = () => {
    return (
        <MainLayout additionalScrollHeight={10} title="Game Rules">
            <div className={styles.wrapper}>
                <FirstBlock />

                <LuckySpinBlock />

                <TypesOfVoteBlock />

                <NomiTaskBlock />

                <DocumentsBlock />
            </div>
        </MainLayout>
    );
};

export default GovernScreen;
