import { FC } from "react";

import { ISvg } from "./SVG.types";

export const RedStarSVG: FC<ISvg> = (props) => {
    return (
        <svg
            fill="none"
            height="16"
            viewBox="0 0 14 16"
            width="14"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M11.4906 14.6803C9.83467 13.4179 8.99681 12.7949 7.30038 11.5687C5.73063 13.0027 4.95652 13.7277 3.42987 15.1923C3.90885 12.991 4.15514 11.8906 4.66007 9.69189C2.93857 8.48859 2.06816 7.89609 0.306641 6.73151C2.36373 6.53028 3.39294 6.44318 5.45266 6.29595C5.97342 4.09872 6.24039 3.00085 6.7871 0.807707C7.53966 2.91832 7.90912 3.97542 8.63616 6.09316C10.6968 5.97854 11.7281 5.93525 13.7905 5.87411C12.1513 7.25521 11.3429 7.95416 9.7463 9.36837C10.4571 11.4902 10.8058 12.5524 11.4906 14.6803Z"
                fill="#B6070C"
            />
        </svg>
    );
};
