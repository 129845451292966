import { useEffect } from "react";

import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";

import TransparentBlock from "@atoms/TransparentBlock/TransparentBlock";
import Typography from "@atoms/Typography";

import MainLayout from "@organisms/layouts/MainLayout";

import { truncateTitle } from "@screens/TasksScreen/components/FrensList";

import { referralsSelector } from "@redux/referrals/referrals.selector";
import { fetchLeaderboard } from "@redux/referrals/referrals.thunk";

import styles from "./leaderboard-screen.module.scss";

const LeaderboardScreen = () => {
    const dispatch = useAppDispatch();

    const { leaderboard, isLeaderboardLoading } =
        useAppSelector(referralsSelector);

    useEffect(() => {
        dispatch(fetchLeaderboard());
    }, [dispatch]);

    return (
        <MainLayout
            withGoBack
            additionalScrollHeight={10}
            bgName="RulesBg"
            title="Leaderboard"
        >
            <div className={styles.wrapper}>
                <Typography
                    style={{ textAlign: "center" }}
                    variant="textRegular"
                >
                    Invite frens to get here
                </Typography>

                <TransparentBlock rootClassName={styles.tableContainer}>
                    <div className={styles.frensTable}>
                        <div className={styles.head}>
                            <Typography
                                className={styles.indexHead}
                                variant="text2Regular"
                            >
                                #
                            </Typography>
                            <Typography
                                className={styles.nicknameHead}
                                variant="text2Regular"
                            >
                                Nickname
                            </Typography>
                            <Typography
                                className={styles.tapsHead}
                                variant="text2Regular"
                            >
                                Frens invited
                            </Typography>
                        </div>
                        <div className={styles.body}>
                            {leaderboard.user && (
                                <div className={styles.userRow}>
                                    <div className={styles.indexBody}>
                                        <Typography variant="text2Regular">
                                            {leaderboard.user.position}
                                        </Typography>
                                    </div>
                                    <div className={styles.nicknameBody}>
                                        <Typography variant="text2Bold">
                                            {truncateTitle(
                                                leaderboard.user.username,
                                                32
                                            )}
                                        </Typography>
                                    </div>
                                    <div className={styles.tapsBody}>
                                        <Typography variant="text2Regular">
                                            {leaderboard.user.total_frens}
                                        </Typography>
                                    </div>
                                </div>
                            )}
                            {leaderboard.leaders.map((el, index) => {
                                return (
                                    <div key={index} className={styles.row}>
                                        <div className={styles.indexBody}>
                                            <Typography variant="text2Regular">
                                                {el.position}
                                            </Typography>
                                        </div>
                                        <div className={styles.nicknameBody}>
                                            <Typography variant="text2Bold">
                                                {truncateTitle(el.username, 32)}
                                            </Typography>
                                        </div>
                                        <div className={styles.tapsBody}>
                                            <Typography variant="text2Regular">
                                                {el.total_frens}
                                            </Typography>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </TransparentBlock>
            </div>
        </MainLayout>
    );
};

export default LeaderboardScreen;
