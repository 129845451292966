import { FC } from "react";

import { ISvg } from "./SVG.types";

export const SuccessBigSVG: FC<ISvg> = (props) => {
    return (
        <svg
            fill="none"
            height="49"
            viewBox="0 0 68 49"
            width="68"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g filter="url(#filter0_d_2050_4032)">
                <path
                    d="M7.33398 21.5795L25.6197 39.6664L60.6673 4.99976"
                    shapeRendering="crispEdges"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeOpacity="0.9"
                    strokeWidth="9"
                />
            </g>
            <defs>
                <filter
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                    height="47.6667"
                    id="filter0_d_2050_4032"
                    width="66.334"
                    x="0.833984"
                    y="0.499756"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
                    />
                    <feBlend
                        in2="BackgroundImageFix"
                        mode="normal"
                        result="effect1_dropShadow_2050_4032"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_2050_4032"
                        mode="normal"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};
