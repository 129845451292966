import { FC } from "react";

import { useAppDispatch } from "@hooks/useAppDispatch";

import Button from "@atoms/Button";
import SunSpinner from "@atoms/SunSpinner";
import TransparentBlock from "@atoms/TransparentBlock/TransparentBlock";
import Typography from "@atoms/Typography";

import { HandPointSVG } from "@svg/HandPointSVG";

import { fetchClaimTaps } from "@redux/referrals/referrals.thunk";

import { Notifications } from "@utils/notifications";

import styles from "../TasksScreen.module.scss";
import { IFrensList } from "./FrensList.types";

export function truncateTitle(title: string, maxLength: number) {
    if (title === null) return "-";

    if (title.length > maxLength) {
        return `${title.slice(0, maxLength - 3)}...`;
    }
    return title;
}

const FrensList: FC<IFrensList> = ({ frensData, isLoading, unclaimedTaps }) => {
    const dispatch = useAppDispatch();

    if (isLoading) return <SunSpinner />;

    const handleClaimTaps = () => {
        if (unclaimedTaps === 0) return;

        dispatch(fetchClaimTaps());
        Notifications.createSuccess("Taps received");
    };

    return (
        <TransparentBlock rootClassName={styles.frensContainer}>
            {frensData.length === 0 ? (
                <div className={styles.placeholder}>
                    <Typography variant="textBold">Frens list</Typography>
                    <Typography variant="text2Regular">No frens yet</Typography>
                </div>
            ) : (
                <>
                    <div className={styles.header}>
                        <Typography
                            className={styles.frensListTitle}
                            variant="textBold"
                        >
                            Frens list
                        </Typography>
                        <Button
                            className={styles.claimBtn}
                            disabled={unclaimedTaps === 0}
                            onClick={handleClaimTaps}
                        >
                            <Typography variant="text3Bold">
                                Claim taps ({unclaimedTaps})
                            </Typography>
                        </Button>
                    </div>
                    <div className={styles.frensTable}>
                        <div className={styles.head}>
                            <Typography
                                className={styles.headText}
                                variant="text2Regular"
                            >
                                Nickname
                            </Typography>
                            <Typography
                                className={styles.headText}
                                variant="text2Regular"
                            >
                                Taps
                            </Typography>
                        </div>
                        <div className={styles.body}>
                            {frensData.map((el, index) => {
                                return (
                                    <div key={index} className={styles.row}>
                                        <div className={styles.left}>
                                            <Typography variant="text2Bold">
                                                {truncateTitle(el.username, 32)}
                                            </Typography>
                                        </div>
                                        <div className={styles.right}>
                                            <Typography variant="text2Regular">
                                                +{el.earn_taps}
                                            </Typography>
                                            <HandPointSVG />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </>
            )}
        </TransparentBlock>
    );
};

export default FrensList;
