export const BASE_URL = import.meta.env.VITE_APP_BACKEND_URL;

export const Headers = {
    "Content-Type": "application/json",
} as const;

export const API_URLS = {
    candidates: "candidates",
    countries: "countries",
    taps: "taps",
    referrals: "referrals",
    auth: "clients/auth",
    constants: "constants/list",
    vote: "votes",
    clients: "clients",
    tasks: "tasks",
} as const;
