import { useNavigate } from "react-router-dom";

import { routes } from "@constants/routes";

import TransparentBlock from "@atoms/TransparentBlock/TransparentBlock";
import Typography from "@atoms/Typography";

import styles from "../govern-screen.module.scss";

const DocumentsBlock = () => {
    const navigate = useNavigate();

    return (
        <>
            <Typography variant="textBold">Documents</Typography>

            <div className={styles.documentBlock}>
                <TransparentBlock>
                    <Typography
                        style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                        }}
                        variant="text2Regular"
                        onClick={() => navigate(routes.privacyPolicy)}
                    >
                        Privacy Policy
                    </Typography>
                </TransparentBlock>

                <TransparentBlock>
                    <Typography
                        style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                        }}
                        variant="text2Regular"
                        onClick={() => navigate(routes.termsCondition)}
                    >
                        Terms & Conditions
                    </Typography>
                </TransparentBlock>

                <TransparentBlock>
                    <Typography
                        style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                        }}
                        variant="text2Regular"
                        onClick={() => navigate(routes.additionTerms)}
                    >
                        Candidate Addition Terms
                    </Typography>
                </TransparentBlock>
            </div>
        </>
    );
};

export default DocumentsBlock;
