import { AxiosPromise } from "axios";

import { API_URLS } from "@constants/api";

import { IConstantKey, IConstants } from "@redux/constants/constants.slice";

import Request from "../../Request";

const url = API_URLS.constants;

export class ConstantsAPI {
    static getConstants(dto: IConstantKey): AxiosPromise<IConstants[]> {
        return Request.get(`${url}/?fields=${dto}`);
    }
}
