import { useEffect, useState } from "react";

export const useIsIos = () => {
    const [isIOS, setIsIOS] = useState(false);

    useEffect(() => {
        const checkIOS = () => {
            setIsIOS(
                // @ts-expect-error
                /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
            );
        };

        checkIOS();
    }, []);

    return isIOS;
};
