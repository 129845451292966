import { useLayoutEffect, useRef, useState } from "react";

import Header from "@organisms/Header";
import Navbar from "@organisms/Navbar";

import mainBg from "../../../../assets/images/MainBg.png";
import rulesBg from "../../../../assets/images/RulesBg.png";
import startBg from "../../../../assets/images/StartBg.png";
import styles from "./MainLayout.module.scss";
import { IMainLayout } from "./MainLayout.types";

const bgVariants = {
    MainBg: mainBg,
    StartBg: startBg,
    RulesBg: rulesBg,
};

const MainLayout: FCC<IMainLayout> = ({
    children,
    subtitle,
    title,
    hasNavbar = true,
    navigateURL,
    additionalScrollHeight = 0,
    withGoBack,
    bgName,
    onBeforeGoBack,
}) => {
    const [navHeight, setNavHeight] = useState(0);

    const navRef = useRef<HTMLDivElement | null>(null);

    useLayoutEffect(() => {
        if (navRef.current) {
            const { height } = navRef.current?.getBoundingClientRect() ?? {};
            setNavHeight(height);
        }
    }, []);

    const currentBg = bgVariants[bgName || "MainBg"];

    const style: React.CSSProperties = {
        background: `url('${currentBg}') no-repeat, radial-gradient(50% 50% at 50% 50%, #0322a5 0%, #010d3f 100%)`,
        backgroundSize: "cover",
        backgroundPosition: "top",
    };

    return (
        <main className={styles.wrapper} style={style}>
            <Header
                navigateURL={navigateURL}
                subtitle={subtitle}
                title={title}
                withGoBack={withGoBack}
                onBeforeGoBack={onBeforeGoBack}
            />
            <div className={styles.content}>{children}</div>
            {hasNavbar ? <Navbar ref={navRef} /> : <> </>}
            <div
                style={{
                    minHeight:
                        (hasNavbar ? navHeight ?? 0 : 0) +
                        additionalScrollHeight,
                    width: "100%",
                }}
            />
        </main>
    );
};

export default MainLayout;
