import { clsx } from "clsx";

import { InputError } from "@atoms/InputError/InputError";
import Typography from "@atoms/Typography";

import { isBoolean } from "@utils/isBoolean";

import styles from "./InputLayout.module.scss";
import { IInputLayout } from "./InputLayout.types";

export const InputLayout: FCC<IInputLayout> = ({
    children,
    className,
    errorText,
    subtitle,
    title,
    inputWrapperClassName,
    focusedBorder,
    isError,
}) => {
    return (
        <div className={clsx(styles.wrapper, className)}>
            {(title || subtitle) && (
                <div className={styles.description}>
                    {title && (
                        <Typography variant="text2Bold">{title}</Typography>
                    )}
                    {subtitle && (
                        <Typography variant="text2Regular">
                            {subtitle}
                        </Typography>
                    )}
                </div>
            )}

            <div
                className={clsx(styles.block, inputWrapperClassName, {
                    [styles.block_error]: !!isError,
                    [styles.block_focused]: !!focusedBorder,
                    [styles.block_focusable]: isBoolean(focusedBorder),
                })}
            >
                {children}
            </div>

            {!!errorText && <InputError>{errorText}</InputError>}
        </div>
    );
};
