import { TNomineesVoteCard } from "@screens/VoteScreen/components/VoteNominees/VoteNominees.types";

import { IMyCandidate, IPublicCandidate } from "@schemas/candidates";

export const isPublicCandidate = (
    candidate: IMyCandidate | IPublicCandidate
): candidate is IPublicCandidate => "percent" in candidate;

export const mapCandidatesToVoteCard = (
    candidates: IMyCandidate[] | IPublicCandidate[]
): TNomineesVoteCard[] => {
    return candidates?.map((candidate) => ({
        id: candidate.id,

        name: candidate.name,
        flags: candidate.countries.map(({ photo }) => photo),
        percent: isPublicCandidate(candidate) ? candidate.percent : undefined,
        votesAmount: isPublicCandidate(candidate)
            ? candidate.total_votes
            : undefined,
        photo: candidate.photo,
    }));
};
