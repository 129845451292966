import { FC, useEffect, useRef, useState } from "react";

import clsx from "clsx";

import { InputLayout } from "@atoms/InputLayout/InputLayout";

import { CloseSimpleSvg } from "@svg/CloseSimpleSVG";

import { isBoolean } from "@utils/isBoolean";

import styles from "./input.module.scss";
import { IInput } from "./input.types";

const Input: FC<IInput> = ({
    title,
    placeholder,
    value,
    onChange,
    error,
    isError,
    className,
    isFocused: isFocusedProps,
    withClear = true,
    rightIcon,
    ...rest
}) => {
    const [isFocused, setIsFocused] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);

    const currentIsFocused = isBoolean(isFocusedProps)
        ? isFocusedProps
        : isFocused;

    useEffect(() => {
        if (isBoolean(isFocusedProps) && !isFocusedProps) {
            inputRef.current?.blur();
        }
    }, [isFocusedProps]);

    return (
        <InputLayout
            className={clsx(styles.wrapper, className)}
            errorText={error}
            focusedBorder={currentIsFocused}
            inputWrapperClassName={styles.block}
            isError={isError}
            title={title}
        >
            <label
                className={clsx(styles.placeholder, {
                    [styles.placeholder_focused]: value || currentIsFocused,
                })}
            >
                {placeholder}
            </label>

            <input
                {...rest}
                ref={inputRef}
                className={styles.input}
                type="text"
                value={value}
                onBlur={() => setIsFocused(false)}
                onChange={onChange}
                onFocus={() => setIsFocused(true)}
            />

            {withClear && !!value && !rightIcon && (
                <button
                    className={styles.clear}
                    disabled={rest.disabled}
                    onClick={() => onChange?.("")}
                >
                    <CloseSimpleSvg />
                </button>
            )}

            {rightIcon}
        </InputLayout>
    );
};

export default Input;
